// colors
export const colors = {
  // primary
  primary: '#266AFD',
  primary10: '#EDF3FF', // bluesoft
  primary20: '#DAE6FF', // navblue
  primary30: '#ABC5FE',
  primary40: '#6898FF',
  primary50: '#266AFD', // primary
  primary60: '#0049E8',
  primary70: '#003EC5',
  primary80: '#002D8E',
  primary90: '#001E60',
  primary100: '#001136',
  // secondary
  secondary: '#FF9E57',
  secondary10: '#FFF6EF',
  secondary20: '#FFE9DA',
  secondary30: '#FECEAB',
  secondary40: '#FF9E57', // secondary
  secondary50: '#FF8F3F',
  secondary60: '#FA6605',
  secondary70: '#C15100',
  secondary80: '#8E3B00',
  secondary90: '#602800',
  secondary100: '#361700',

  // common
  white: '#fff',
  black: '#272D3B',
  red: '#F24242',
  redsoft: '#e76969',
  green: '#27ae60',
  successgreen: '#00AF85',
  yellow: '#f1ca4b',
  border: '#e0e0e0',
  BLACK_1: '#1A1A1A',

  RED_0: '#FFF8F8',
  RED_10: '#FFECEC',
  RED_20: '#ffc2c2',
  RED_70: '#AE1E1E',
  RED_80: '#8C1111',

  GREEN_0: '#F4FFFD',
  GREEN_10: '#CCEFE7',
  GREEN_20: '#AAE4D6',
  GREEN_40: '#55CAAE',
  GREEN_60: '#00AF85',
  GREEN_70: '#00926F',
  GREEN_90: '#005742',

  YELLOW_0: '#FFFEF8',
  YELLOW_10: '#FFFCEB',
  YELLOW_20: '#FFF6BD',
  YELLOW_30: '#FFEF90',
  YELLOW_60: '#DDC323',
  YELLOW_70: '#BBA414',

  grey: '#bdbdbd',
  grey10: '#FAFAFA',
  grey20: '#F2F2F2',
  grey30: '#E0E0E0',
  grey40: '#BDBDBD',
  grey50: '#828282',
  grey60: '#4F4F4F',

  // updated //
  PRIMARY_25: '#F8FAFF',
  PRIMARY_50: '#E9F0FF',
  PRIMARY_100: '#B8CFFF',
  PRIMARY_200: '#88ADFF',
  PRIMARY_300: '#578CFF',
  PRIMARY_400: '#266AFD',
  PRIMARY_500: '#1453DB',
  PRIMARY_600: '#1453DB',
  PRIMARY_700: '#003097',
  PRIMARY_800: '#002575',
  PRIMARY_900: '#001A53',

  ORANGE_0: '#FFF6EF',
  ORANGE_25: '#FFF9F5',
  ORANGE_50: '#FFF0E6',
  ORANGE_100: '#FFD5B9',
  ORANGE_200: '#FFB98C',
  ORANGE_300: '#FF9E5F',
  ORANGE_400: '#FF8332',
  ORANGE_500: '#FA6605',
  ORANGE_600: '#D15300',
  ORANGE_700: '#A84300',
  ORANGE_800: '#803300',
  ORANGE_900: '#572200',

  TOSCA_25: '#F3FDFF',
  TOSCA_50: '#DAFBFF',
  TOSCA_100: '#C0F8FF',
  TOSCA_200: '#A6F5FF',
  TOSCA_300: '#8CF2FF',
  TOSCA_400: '#6FD7E4',
  TOSCA_500: '#53B5C2',
  TOSCA_600: '#3A94A0',
  TOSCA_700: '#26747E',
  TOSCA_800: '#16545C',
  TOSCA_900: '#003B43',

  GRAY_20: '#F9FAFB',
  GRAY_25: '#F6F6F6',
  GRAY_50: '#EFEEEE',
  GRAY_100: '#F2F4F7',
  GRAY_200: '#E6E6E8',
  GRAY_300: '#CED2D8',
  GRAY_400: '#B1B5BA',
  GRAY_500: '#898D94',
  GRAY_600: '#64686D',
  GRAY_700: '#56585C',
  GRAY_800: '#2F3133',
  GRAY_900: '#151616',

  RED_25: '#FFFBFA',
  RED_50: '#FEF3F2',
  RED_100: '#FEE4E2',
  RED_200: '#FECDCA',
  RED_300: '#FDA29B',
  RED_400: '#F97066',
  RED_500: '#F04438',
  RED_600: '#D92D20',
  RED_700: '#B42318',
  RED_800: '#912018',
  RED_900: '#7A271A',

  GREEN_25: '#F6FEF9',
  GREEN_50: '#ECFDF3',
  GREEN_100: '#D1FADF',
  GREEN_200: '#A6F4C5',
  GREEN_300: '#6CE9A6',
  GREEN_400: '#32D583',
  GREEN_500: '#12B76A',
  GREEN_600: '#039855',
  GREEN_700: '#027A48',
  GREEN_800: '#05603A',
  GREEN_900: '#054F31',

  YELLOW_25: '#FFFCF5',
  YELLOW_50: '#FFFAEB',
  YELLOW_100: '#FEF0C7',
  YELLOW_200: '#FEDF89',
  YELLOW_300: '#FEC84B',
  YELLOW_400: '#FDB022',
  YELLOW_500: '#F79009',
  YELLOW_600: '#DC6803',
  YELLOW_700: '#B54708',
  YELLOW_800: '#93370D',
  YELLOW_900: '#7A2E0E',
}

// fonts
export const fonts = {
  opensans: "'Open Sans', sans-serif",
  montserrat: "'Montserrat', sans-serif",
  firasans: "'Fira Sans', sans-serif",
  lato: "'Lato', sans-serif",
  roboto: "'Roboto', sans-serif",
}

// breakpoints
export const breakpoints = ['768px', '992px', '1200px']
