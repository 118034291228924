import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { breakpoints, colors, fonts } from '../styles/styles'
import { Link } from 'react-router-dom'
const {
  black,
  primary,
  primary10,
  primary80,
  border,
  secondary,
  redsoft,
  white,
  secondary10,
  TOSCA_600,
} = colors
const { firasans, lato, roboto } = fonts

const btn = css`
  display: inline-block;
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 13px;
  transition: 0.1s all;
  &:focus,
  &:active:focus,
  &.active:focus {
    outline: none;
    box-shadow: none;
  }
`

const btncolor = {
  primary: css`
    background-color: ${primary};
    color: ${white};
    &:disabled {
      color: #8d8d8d;
      border: 1px solid #ced2d8;
    }
    &:hover:not(:disabled) {
      color: ${white};
      opacity: 0.9;
    }
  `,
  'primary-ghost': css`
    background-color: transparent;
    &:not(:disabled) {
      color: ${primary} !important;
      border: 1px solid ${primary};
    }
    &:disabled {
      color: #8d8d8d;
      border: 1px solid #8d8d8d;
    }
    &:hover:not(:disabled) {
      background-color: ${primary};
      color: ${white} !important;
    }
  `,
  'primary-link': css`
    background-color: transparent;
    color: ${primary};
    &:hover {
      color: ${primary};
      opacity: 0.9;
    }
  `,
  softprimary: css`
    background-color: ${primary10};
    color: ${primary};
    &:hover {
      color: ${primary};
      opacity: 0.9;
    }
  `,
  darkprimary: css`
    background-color: ${primary80};
    color: ${white};
    &:hover {
      color: ${white};
      opacity: 0.9;
    }
  `,
  secondary: css`
    background-color: ${secondary};
    color: ${white};
    border-color: ${secondary};
    &:hover {
      color: ${white};
      opacity: 0.9;
    }
  `,
  softsecondary: css`
    background-color: ${secondary10};
    color: ${secondary};
    border-color: ${secondary10};
    &:hover {
      color: ${secondary};
      opacity: 0.9;
    }
  `,
  red: css`
    background-color: ${redsoft};
    color: ${white};
    &:hover {
      color: ${white};
      opacity: 0.9;
    }
  `,
  'red-ghost': css`
    background-color: transparent;
    color: ${redsoft};
    border: 1px solid ${redsoft};
    &:hover {
      background-color: ${redsoft};
      color: ${white};
    }
  `,
  'red-link': css`
    background-color: transparent;
    color: ${redsoft};
    &:hover {
      color: ${redsoft};
      opacity: 0.9;
    }
  `,
  white: css`
    background-color: ${white};
    color: ${primary};
    &:hover {
      color: ${primary};
      opacity: 0.9;
    }
  `,
  tosca: css`
    background-color: ${TOSCA_600};
    color: ${white};
    &:hover {
      color: ${white};
      opacity: 0.9;
    }
  `,
}

const Relative = styled.div`
  position: relative;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor};
  overflow: ${(props) => props.overflow};
  z-index: ${(props) => props.zIndex};
  box-shadow: ${(props) => props.boxShadow};
  display: ${(props) => props.display};
  min-height ${(props) => props.minHeight};
  @media (min-width: ${breakpoints[0]}) and (max-width: ${breakpoints[2]}) {
    ${(props) => props.mediumStyle};
  }
  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Fixed = styled.div`
  position: fixed;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Absolute = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  cursor: ${(props) => props.cursor};
  transform: ${(props) => props.transform};
  z-index: ${(props) => props.zIndex};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const FormGroup = styled.div`
  position: relative;
  margin: ${(props) => props.margin || '0 0 30px'};
  width: 100%;
`

const Flex = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};
  border-top: ${(props) => props.borderTop};
  border-right: ${(props) => props.borderRight};
  border-radius: ${(props) => props.borderRadius};
  overflow: ${(props) => props.overflow};
  white-space: ${(props) => props.whiteSpace};
  gap: ${(props) => props.gap};
  opacity: ${(props) => props.opacity};
  z-index: ${(props) => props.zIndex};
  cursor: ${(props) => props.cursor};
  min-height ${(props) => props.minHeight};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const FlexCell = styled.div`
  flex: ${(props) => props.flex};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  border: ${(props) => props.border};
  background-color: ${(props) => props.backgroundColor};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const FlexCellShadow = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  flex: ${(props) => props.flex};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.textAlign};
  -webkit-box-shadow: 0 4px 6px -6px black;
  -moz-box-shadow: 0 4px 6px -6px black;
  box-shadow: 0 4px 6px -6px black;
`

const InlineBlock = styled.div`
  display: inline-block;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  vertical-align: ${(props) => props.verticalAlign};
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const WhiteSpace = styled.div`
  white-space: ${(props) => props.type};
  padding: ${(props) => props.padding};
`

const Text = styled.div`
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.margin};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color || black};
  font-style: ${(props) => props.fontStyle};
  text-transform: ${(props) => props.textTransform};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  display: ${(props) => (props.display ? props.display : 'block')};
  font-family: ${(props) =>
    props.title ? firasans : props.fontFamily || roboto};
  padding: ${(props) => props.padding};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.whiteSpace};
  letter-spacing: ${(props) => props.letterSpacing};
  line-height: ${(props) => props.lineHeight};
  word-break: ${(props) => props.wordBreak};
  max-width: ${(props) => props.maxWidth};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Span = styled.span`
  background-color: ${(props) => props.backgroundColor};
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.margin};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color || black};
  font-style: ${(props) => props.fontStyle};
  text-transform: ${(props) => props.textTransform};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  font-family: ${(props) =>
    props.title ? firasans : props.fontFamily || lato};
  padding: ${(props) => props.padding};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.whiteSpace};
  letter-spacing: ${(props) => props.letterSpacing};
  line-height: ${(props) => props.lineHeight};
  word-break: ${(props) => props.wordBreak};
  border-radius: ${(props) => props.borderRadius};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Image = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  object-fit: ${(props) => props.objectFit};
  object-position: ${(props) => props.objectPosition};
  cursor: ${(props) => props.cursor};
  position: ${(props) => props.position};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor};
  display: ${(props) => props.display};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Separator = styled.div`
  border-bottom: ${(props) => props.borderWidth || '1px'}
    ${(props) => props.borderStyle || 'solid'}
    ${(props) => props.borderColor || border};
  margin: ${(props) => props.margin};
`

const Divider = styled.div`
  display: inline-block;
  width: 100%;
  background-color: #eeeeee;
  margin: 0;
  height: ${(props) => props.height || '1px'};
`

const Icon = styled.i`
  margin: ${(props) => props.margin};
  cursor: ${(props) => props.cursor};
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
`

const BoxShadow = styled.div`
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.2);
  padding: ${(props) => props.padding || '20px 25px'};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
  margin: ${(props) => props.margin};
`

const Card = styled.div`
  position: relative;
  box-shadow: ${(props) =>
    props.boxShadow || '0 1px 20px 0 rgba(155, 155, 155, 0.2)'};
  padding: ${(props) => props.padding || '0'};
  border-radius: ${(props) => props.borderRadius || '7px'};
  background-color: ${(props) => props.backgroundColor || white};
  margin: ${(props) => props.margin || '0 0 30px'};
  overflow: ${(props) => props.overflow};
  max-width: ${(props) => props.maxWidth};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const ButtonLinkStyled = styled(Link)`
  ${btn};
  border: ${(props) => props.border};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'inherit')};
  border-radius: ${(props) => (props.rounded ? '50%' : props.radius || '4px')};
  padding: ${(props) => props.padding || '8px 12px'};
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => (props.block ? '100%' : props.width)};
  border-color: ${(props) => props.borderColor};
  font-size: ${(props) => props.fontSize};
  ${(props) => btncolor[props.color]};
  background-color: ${(props) =>
    props.disabled ? '#F1F1F1' : props.backgroundColor};
  color: ${(props) => props.textColor};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    text-decoration: none;
  }

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const ButtonStyled = styled.button`
  ${btn};
  border: ${(props) => props.border};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'inherit')};
  border-radius: ${(props) => (props.rounded ? '50%' : props.radius || '4px')};
  padding: ${(props) => props.padding || '8px 12px'};
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => (props.block ? '100%' : props.width)};
  border-color: ${(props) => props.borderColor};
  font-size: ${(props) => props.fontSize};
  ${(props) => btncolor[props.color]};
  background-color: ${(props) =>
    props.disabled ? '#F1F1F1' : props.backgroundColor};
  color: ${(props) => props.textColor};
  height: ${(props) => props.height};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

const Dropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
  border-radius: 7px;
  background-color: transparent;
  color: #9b9b9b;
  outline: none;
  transition: border 0.24s ease-in-out;
  border: 1px dashed ${primary};
  width: 60%;
  margin: ${(props) => props.margin || '30px auto'};
`
const Wrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};
  border-right: ${(props) => props.borderRight};
  border-radius: ${(props) => props.borderRadius};
  overflow: ${(props) => props.overflow};
  white-space: ${(props) => props.whiteSpace};
  opacity: ${(props) => props.opacity};
  z-index: ${(props) => props.zIndex};
  cursor: ${(props) => props.cursor};

  @media (max-width: ${breakpoints[0]}) {
    ${(props) => props.mobileStyle}
  }
`

export {
  btn,
  Relative,
  Absolute,
  FormGroup,
  Flex,
  FlexCell,
  FlexCellShadow,
  InlineBlock,
  WhiteSpace,
  Text,
  Image,
  Separator,
  Icon,
  Fixed,
  Divider,
  BoxShadow,
  Card,
  ButtonLinkStyled,
  ButtonStyled,
  Dropzone,
  Span,
  Wrapper,
}
