import React from 'react'
import {
  BsColumnsGap,
  BsFileText,
  BsGear,
  BsLayoutTextWindowReverse,
  BsListUl,
} from 'react-icons/bs'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { GoPackage } from 'react-icons/go'
import { ImLoop2 } from 'react-icons/im'
import { IoMdSwap } from 'react-icons/io'
import { RiDatabase2Line, RiScalesLine, RiStore3Line } from 'react-icons/ri'

export default {
  dashboard: (
    <BsColumnsGap
      className="c-sidebar-nav-icon o-sidebar-icon"
      style={{ fontSize: '1.05rem', height: '1.05rem' }}
    />
  ),
  product: (
    <BsListUl
      className="c-sidebar-nav-icon o-sidebar-icon"
      style={{ fontSize: '1.22rem', height: '1.22rem' }}
    />
  ),
  data: <RiDatabase2Line className="c-sidebar-nav-icon o-sidebar-icon" />,
  warehouse: (
    <RiStore3Line
      className="c-sidebar-nav-icon o-sidebar-icon"
      style={{ fontSize: '1.3rem', height: '1.3rem' }}
    />
  ),
  transaction: <GoPackage className="c-sidebar-nav-icon o-sidebar-icon" />,
  accounting: (
    <RiScalesLine
      className="c-sidebar-nav-icon o-sidebar-icon"
      style={{ fontSize: '1.3rem', height: '1.3rem' }}
    />
  ),
  finance: (
    <FaRegMoneyBillAlt
      className="c-sidebar-nav-icon o-sidebar-icon"
      style={{ fontSize: '1.05rem', height: '1.05rem' }}
    />
  ),
  report: (
    <BsLayoutTextWindowReverse
      className="c-sidebar-nav-icon o-sidebar-icon"
      style={{ fontSize: '1.1rem', height: '1.1rem' }}
    />
  ),
  settings: <BsGear className="c-sidebar-nav-icon o-sidebar-icon" />,
  billing: (
    <ImLoop2
      className="c-sidebar-nav-icon o-sidebar-icon"
      style={{ fontSize: '1.1rem', height: '1.1rem' }}
    />
  ),
  creditdebt: (
    <IoMdSwap
      className="c-sidebar-nav-icon o-sidebar-icon"
      style={{ fontSize: '1.3rem', height: '1.3rem' }}
    />
  ),
  spending: (
    <BsFileText
      className="c-sidebar-nav-icon o-sidebar-icon"
      style={{ fontSize: '1.25rem', height: '1.25rem' }}
    />
  ),
}
