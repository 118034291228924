import React from 'react'
import { FiCheck } from 'react-icons/fi'

const Checkbox = ({
  className = '',
  color = 'primary',
  defaultChecked,
  checked,
  value,
  disabled = false,
  onClick = () => {},
  onChange = () => {},
  size = 'md',
  label,
  icon = (
    <div className="vx-icon">
      <FiCheck size={12} />
    </div>
  ),
  id,
  labelStyle,
  style,
}) => {
  return (
    <div
      className={`vx-checkbox-con ${className} vx-checkbox-${color}`}
      style={style}
    >
      <input
        type="checkbox"
        id={id}
        name={id}
        defaultChecked={defaultChecked}
        checked={checked}
        value={value}
        disabled={disabled}
        onClick={onClick}
        onChange={onChange}
      />
      <span className={`vx-checkbox vx-checkbox-${size}`}>
        <span className="vx-checkbox--check">{icon}</span>
      </span>
      {label && (
        <span
          className="vx-checkbox-label"
          style={{ marginLeft: '0.5rem', ...labelStyle }}
        >
          {label}
        </span>
      )}
    </div>
  )
}

export default Checkbox
