import React, { useEffect, useState } from 'react'
import CustomModal from './CustomModal'
import {
  Divider,
  Flex,
  FlexCell,
  Image,
  Relative,
  Span,
  Text,
} from 'assets/components/components'
import { colors } from 'assets/styles/styles'
import images from 'assets/images'
import Input from 'components/Input/Input'
import Textarea from 'components/Input/Textarea'
import Button from 'components/Button/Button'
import { useAsyncFn } from 'react-use'
import { useSelector } from 'react-redux'
import ajax from './ajax'

import { ONSTOCK_CS_PHONE_NUMBER } from 'config/env'

const ModalRequestDemo = ({ visibility, onClose }) => {
  const [phone, setPhone] = useState('')
  const [formError, setFormError] = useState({ phone: '' })
  const [error, setError] = useState('')
  const [msg, setMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)

  const token = useSelector((state) => state.token)
  const user = useSelector((state) => state.user)

  const [fetchState, reqDemoFetch] = useAsyncFn(async (phone, msg, token) => {
    setIsLoading(true)
    if (!phone) {
      setFormError({ ...formError, phone: 'No. Whatsapp aktif harus diisi' })
      setIsLoading(false)
      return
    }

    const res = await ajax.createRequestDemo(phone, msg, token)
    setIsLoading(false)
    if (!res.success) {
      setError(res?.message)
      return false
    }

    setIsSubmitSuccess(true)
  }, [])

  const onSendMessageToWhatsapp = () => {
    window.open(`https://wa.me/${ONSTOCK_CS_PHONE_NUMBER}?text=
    Halo, saya dari ${user?.name} - ${user?.email} - ${user?.store?.name}, tertarik untuk mengadakan sesi demo GRATIS dengan Onstock. Boleh tolong diaturkan tanggal dan jam demonya? %0a Terima kasih.`)
  }

  const formStateContent = (
    <>
      <Text
        color={colors?.primary}
        textAlign={'center'}
        fontWeight={'bold'}
        fontSize={'24px'}
      >
        Request Demo GRATIS
      </Text>
      <Text
        textAlign={'center'}
        fontSize={'14px'}
        margin={'.5rem 0 0 0'}
        color={colors?.black}
      >
        Kenali Onstock lebih lanjut, kamu dapat menjadwalkan demo dengan tim
        kami tanpa dipungut biaya apapun loh.
      </Text>
      <Flex justifyContent={'center'} margin={'1.5rem auto'}>
        <Image
          src={images.figure.figureContactUs}
          alt={'contact us'}
          width={'214px'}
          height={'187px'}
        />
      </Flex>

      <Flex>
        <FlexCell flex={1.0}>
          <Input
            id="phone"
            name="phone"
            label="No. Whatsapp Aktif"
            prepend={<Text>+62</Text>}
            type={'text'}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value)
              setFormError({ ...formError, phone: '' })
            }}
            wrapperStyle={{ marginBottom: 0 }}
            style={{ paddingLeft: 44, paddingBottom: 9 }}
            error={formError?.phone}
          />
        </FlexCell>
      </Flex>

      <Flex margin={'1.5rem 0 0 0'}>
        <FlexCell flex={1.0}>
          <Textarea
            id="phone"
            name="phone"
            type="textarea"
            placeholder={
              'Deskripsikan kebutuhanmu untuk penggunaan aplikasi Onstock.'
            }
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
            wrapperStyle={{ marginBottom: 0 }}
            style={{ resize: 'none' }}
          />
        </FlexCell>
      </Flex>
    </>
  )

  const successStateContent = (
    <>
      <Flex justifyContent={'center'} margin={'1.5rem auto'}>
        <Image
          src={images.figure.figureMailSent}
          alt={'contact us'}
          width={'214px'}
          height={'187px'}
        />
      </Flex>

      <Text
        textAlign={'center'}
        color={colors.black}
        fontWeight={'bold'}
        fontSize={'18px'}
      >
        Pesanmu telah terkirim!
      </Text>
      <Text textAlign={'center'} color={colors.black} fontSize={'18px'}>
        Tim kami akan segera menghubungi kamu.
      </Text>

      <Flex justifyContent={'center'} margin={'1rem auto'}>
        <Divider />
        <Text margin={'auto 1rem'} fontSize={'14px'}>
          Atau
        </Text>
        <Divider />
      </Flex>

      <Relative
        border={`1px solid ${colors.primary10}`}
        padding={'.5rem 1rem'}
        borderRadius={'.5rem'}
      >
        <Text fontSize={'14px'}>
          Halo, saya dari{' '}
          <Span fontWeight={'700'}>
            {' '}
            {user?.name || ''} - {user?.email || ''} - {user?.store?.name || ''}
          </Span>
          , tertarik untuk mengadakan sesi demo GRATIS dengan Onstock.
          <Text>Boleh tolong diaturkan tanggal dan jam demonya?</Text>
        </Text>
        <Text fontSize={'14px'}>Terima kasih.</Text>
      </Relative>
    </>
  )

  const init = () => {
    setPhone('')
    setMsg('')
    setIsLoading(false)
    setIsSubmitSuccess(false)
    setFormError({ phone: '' })
    setError('')
  }

  const close = () => {
    init()
    onClose()
  }

  return (
    <CustomModal
      visibility={visibility}
      modalSize={'sm'}
      onClose={close}
      content={isSubmitSuccess ? successStateContent : formStateContent}
      renderButton={
        isSubmitSuccess ? (
          <Relative margin={'1rem auto'}>
            <Button
              color="primary"
              radius="22px"
              padding="12px 45px"
              uppercase
              loadingWhite
              onClick={onSendMessageToWhatsapp}
              block
            >
              Kirim ke Whatsapp Onstock
            </Button>
          </Relative>
        ) : (
          <>
            <Flex margin={'1.5rem 0 0 0 '}>
              <FlexCell flex={0.5}>
                <Button
                  block
                  color={'primary'}
                  radius={'20px'}
                  onClick={() => reqDemoFetch(phone, msg, token)}
                  loading={isLoading}
                >
                  Ajukan Demo
                </Button>
              </FlexCell>
              <FlexCell flex={0.5}>
                <Text
                  textAlign={'center'}
                  color={colors?.primary}
                  fontWeight={'bold'}
                  onClick={close}
                  cursor={'pointer'}
                >
                  Nanti Saja
                </Text>
              </FlexCell>
            </Flex>

            {error ? (
              <Text
                color={colors.red}
                margin={'.5rem 0 0 0'}
                textAlign={'center'}
              >
                {error}
              </Text>
            ) : null}
          </>
        )
      }
    />
  )
}

export default ModalRequestDemo
