import is from 'is_js'
import React, { useRef } from 'react'
import { Flex, FlexCell, Image, Text } from '../../assets/components/components'
import images from '../../assets/images'
import { compressImage } from '../../utils/format'

const InputImage = ({ placeholder, value, onChange, disabled }) => {
  const input = useRef(null)
  let val = is.object(value)
    ? window.URL.createObjectURL(value)
    : value || images.icon.picture

  return (
    <>
      <div
        className="o-input-image"
        onClick={() => {
          if (disabled) return false
          input.current?.click()
        }}
      >
        <Flex>
          <FlexCell>
            <Image src={val} width="60px" height="60px" objectFit="contain" />
          </FlexCell>
          <FlexCell padding="0 17px">
            {placeholder ? (
              <Text opacity="0.5" fontSize="12px" margin="0 0 5px">
                {placeholder}
              </Text>
            ) : null}
            {disabled ? null : <div className="choose">Choose File</div>}
          </FlexCell>
        </Flex>
      </div>
      <input
        type="file"
        ref={input}
        accept="image/*"
        onChange={async (e) => {
          if (e.target.files?.length > 0) {
            // e.target.files = [compressImage(e.target.files[0])]
            let file = await compressImage(e.target.files[0])
            onChange({
              target: {
                files: [file],
              },
            })
          }
        }}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default InputImage
