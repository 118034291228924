import { BASE_URL, NODE_ENV, ONSTOCK_STORAGE } from 'config/env'

let prefix = 'core'

const developments = {
  'localhost:3000': true,
  'localhost:3001': true,
  'stagingapps.onstock.id': true,
}

if (developments[window.location.host]) {
  prefix = 'staging'
}

let baseURL = BASE_URL

let dummypic =
  'https://res.cloudinary.com/belicharcom/image/upload/v1531557474/man_mtyp4x.png'
let dummypic2 =
  'https://res.cloudinary.com/belicharcom/image/upload/v1585491469/woman_swvaj5.png'
let dummyplaceholder = 'https://via.placeholder.com/300.jpg?text=No+Image'
let host = `https://${window.location.host}`
let storagehost = ONSTOCK_STORAGE

let env = NODE_ENV

export {
  baseURL,
  host,
  dummypic,
  dummypic2,
  dummyplaceholder,
  storagehost,
  env,
}
