import React from 'react'
import { Modal } from 'antd'

import { Relative } from 'assets/components/components'

const CustomModal = ({
  visibility,
  onClose,
  title = '',
  content,
  modalClassName,
  modalBodyStyle,
  renderButton,
  modalWidth,
  isBackdropCloseOnClick,
  closable = true,
  footer,
  noPadding,
}) => {
  if (!visibility) return null

  const bodyStyle = { ...modalBodyStyle, maxHeight: '80vh', overflow: 'auto' }

  return (
    <Modal
      title={title ? title : <Relative style={{ padding: '1rem' }}></Relative>}
      open={visibility}
      closable={closable}
      maskClosable={isBackdropCloseOnClick}
      className={[
        modalClassName,
        noPadding
          ? 'antd-modal-body-no-padding ant-modal-header-no-bottom'
          : '',
      ].join(' ')}
      width={modalWidth ? modalWidth : '575px'}
      centered
      footer={footer ? footer : null}
      onCancel={onClose}
      bodyStyle={bodyStyle}
      zIndex={'1050'}
    >
      {content}
      {renderButton}
    </Modal>
  )
}

export default CustomModal
