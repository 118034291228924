import moment from 'moment'

const url_contact =
  'https://docs.google.com/forms/d/e/1FAIpQLSeZAeU3z5vth2lBG1rDgSIj8fkQyTwrkBMNpH9oUtPeopKFXA/viewform'

const basedate = [moment().startOf('month'), moment().endOf('month')]

const customerType = [
  {
    name: 'Reguler',
    value: 1,
  },
  {
    name: 'Dropshipper',
    value: 2,
  },
  {
    name: 'Distributor',
    value: 3,
  },
]

const customerTypeMap = {
  1: 'Reguler',
  2: 'Dropshipper',
  3: 'Distributor',
}

const accountingType = [
  {
    name: 'Customer',
    value: 1,
  },
  {
    name: 'Supplier',
    value: 2,
  },
  {
    name: 'Marketplace',
    value: 3,
  },
  {
    name: 'Pegawai',
    value: 4,
  },
]

const accountingTypeMap = {
  1: 'Customer',
  2: 'Supplier',
  3: 'Marketplace',
  4: 'Pegawai',
}

const mapMenuDesc = {
  1: 'Melihat data statistik transaksi',
  2: 'Menambah, menghapus dan mengambil daftar produk',
  3: 'Menambah, menghapus dan mengedit pusat data',
  9: 'Menambah, menghapus dan mengedit status transaksi',
  14: 'Menambah, menghapus dan mengedit akuntansi perusahaan',
  19: 'Download dan akses terhadap laporan perusahaan',
  25: 'Pengaturan dari variabel - variabel yang digunakan',
  26: 'Menambah, menghapus dan mengedit lokasi gudang',
}

const packages = [
  {
    type: 1,
    name: 'Premium',
    desc: 'Baru mulai usaha? Yuk, upgrade ke Premium!',
    price: {
      m: 279000,
      a: 3069000,
    },
    prices: [
      {
        period: 1,
        name: 'Bulanan',
        code: 'm',
        iconType: 1,
        period_name: 'bulan',
        price: 234000,
        price_before_discount: 279000,
      },
      {
        period: 3,
        name: '3 Bulan',
        code: 'm',
        iconType: 1,
        discount_percent: 10,
        price_after_promo: 669600,
        period_name: '3 bulan',
        price: 631800,
        price_before_discount: 702000,
        price_per_month: 210600,
      },
      {
        period: 6,
        name: '6 Bulan',
        discount: 279000,
        code: 'm',
        iconType: 1,
        discount_percent: 15,
        price_after_promo: 1171800,
        period_name: '6 bulan',
        price: 1193400,
        price_before_discount: 1404000,
        price_per_month: 198900,
      },
      {
        period: 12,
        name: 'Tahunan',
        discount: 279000,
        code: 'a',
        iconType: 2,
        discount_percent: 20,
        price_after_promo: 1841400,
        period_name: 'tahun',
        iconType: 2,
        price: 2059200,
        price_before_discount: 2574000,
        price_per_month: 171600,
      },
    ],
    oclock_prices: [
      {
        period: 1,
        name: 'Bulanan',
        price: 279000,
        code: 'm',
        iconType: 1,
        period_name: 'bulan',
      },
      {
        period: 3,
        name: '3 Bulan',
        price: 209250,
        code: 'm',
        iconType: 1,
        discount_percent: 20,
        price_after_promo: 669600,
        period_name: '3 bulan',
      },
      {
        period: 6,
        name: '6 Bulan',
        price: 195300,
        discount: 279000,
        code: 'm',
        iconType: 1,
        discount_percent: 30,
        price_after_promo: 1171800,
        period_name: '6 bulan',
      },
      {
        period: 12,
        name: 'Tahunan',
        price: 153450,
        discount: 279000,
        code: 'a',
        iconType: 2,
        discount_percent: 40,
        price_after_promo: 1841400,
        period_name: 'tahun',
      },
    ],
    features: [
      {
        name: 'Transaksi Unlimited',
      },
      {
        name: 'Termasuk 2 user (add-ons available)',
      },
      {
        name: 'Unlimited SKU',
      },
      {
        name: 'Download Laporan Keuangan',
      },
      {
        name: 'Bantuan onboarding oleh tim Onstock',
      },
    ],
    recommended: true,
  },
  {
    type: 2,
    name: 'Pro',
    desc: 'Paket bisnis lebih lengkap untuk kamu para pebisnis pro!',
    price: {
      m: 1199000,
      a: 13189000,
    },
    prices: [
      {
        period: 1,
        name: 'Bulanan',
        price: 1199000,
        code: 'm',
        iconType: 3,
        period_name: 'bulan',
      },
      {
        period: 12,
        name: 'Tahunan',
        price: 1099000,
        discount: 1199000,
        code: 'a',
        iconType: 4,
        period_name: 'bulan',
      },
    ],
    features: [
      {
        name: 'Transaksi Unlimited',
      },
      {
        name: 'Termasuk 25 user (add-ons available)',
      },
      {
        name: 'Unlimited SKU',
      },
      {
        name: 'Download Laporan Keuangan',
      },
      {
        name: 'Bantuan onboarding oleh tim Onstock',
      },
      {
        name: 'Server & Bantuan Prioritas',
      },
    ],
  },
]

const bank = [
  {
    name: 'BCA',
    value: 'BCA',
  },
  {
    name: 'BNI',
    value: 'BNI',
  },
  {
    name: 'BRI',
    value: 'BRI',
  },
  {
    name: 'BTN',
    value: 'BTN',
  },
  {
    name: 'BTPN',
    value: 'BTPN',
  },
  {
    name: 'Citibank',
    value: 'Citibank',
  },
  {
    name: 'CIMB Niaga',
    value: 'CIMB Niaga',
  },
  {
    name: 'Danamon',
    value: 'Danamon',
  },
  {
    name: 'DBS',
    value: 'DBS',
  },
  {
    name: 'Mandiri',
    value: 'Mandiri',
  },
  {
    name: 'Maybank',
    value: 'Maybank',
  },
  {
    name: 'Mega',
    value: 'Mega',
  },
  {
    name: 'OCBC NISP',
    value: 'OCBC NISP',
  },
  {
    name: 'Panin Bank',
    value: 'Panin Bank',
  },
  {
    name: 'Permata',
    value: 'Permata',
  },
  {
    name: 'Lainnya',
    value: '-1',
  },
]

const sources = [
  {
    name: 'Teman / Kolega',
    value: 'Teman / Kolega',
  },
  {
    name: 'Instagram',
    value: 'Instagram',
  },
  {
    name: 'Tiktok',
    value: 'Tiktok',
  },
  {
    name: 'Event / Acara',
    value: 'Event / Acara',
  },
]

const categories = [
  {
    name: 'Fashion',
    value: 'Fashion',
  },
  {
    name: 'Elektronik',
    value: 'Elektronik',
  },
  {
    name: 'Kecantikan',
    value: 'Kecantikan',
  },
  {
    name: 'Makanan & Minuman',
    value: 'Makanan & Minuman',
  },
  {
    name: 'Mainan / Toys',
    value: 'Mainan / Toys',
  },
  {
    name: 'Konveksi',
    value: 'Konveksi',
  },
  {
    name: 'Lainnya',
    value: 'Lainnya',
  },
]

const stockFilterType = [
  {
    id: 0,
    name: 'Semua Stok',
    value: 0,
    label: 'Semua Stok',
  },
  {
    id: 1,
    name: 'Stok Menipis',
    value: 1,
    label: 'Stok Menipis',
  },
  {
    id: 2,
    name: 'Stok Habis',
    value: 2,
    label: 'Stok Habis',
  },
  {
    id: 3,
    name: 'Stok Tersedia',
    value: 3,
    label: 'Stok Tersedia',
  },
]

const statuses = [
  {
    name: 'Aktif',
    label: 'Aktif',
    value: 1,
  },
  {
    name: 'Tidak Aktif',
    label: 'Tidak Aktif',
    value: 0,
  },
]

const pricing = [
  {
    name: 'Fitur Inventori Gudang',
    free: true,
    premium: true,
    business: true,
  },
  {
    name: 'Fitur Barang Masuk-Keluar',
    free: true,
    premium: true,
    business: true,
  },
  {
    name: 'Multi Lokasi',
    free: '1',
    premium: '1',
    business: '5',
  },
  {
    name: 'Fitur Akuntansi',
    free: true,
    premium: true,
    business: true,
  },
  {
    name: 'Web Application',
    free: true,
    premium: true,
    business: true,
  },
  {
    name: 'Mobile Application',
    free: true,
    premium: true,
    business: true,
  },
  {
    name: 'User',
    free: 'Max. 2 user',
    premium: '5 user',
    business: '25 user',
  },
  {
    name: 'Jumlah Transaksi',
    free: 'Max. 50jt \n per bulan',
    premium: 'Unlimited',
    business: 'Unlimited',
  },
  {
    name: 'Laporan Keuangan',
    free: 'Max. Histori 3 bulan \n ke belakang',
    premium: true,
    business: true,
  },
  {
    name: 'Fitur Barang Produksi',
    free: true,
    premium: true,
    business: true,
  },
  {
    name: 'Unduh Laporan Keuangan',
    free: false,
    premium: true,
    business: true,
  },
  {
    name: 'Proses Onboarding oleh Tim Onstock',
    free: false,
    premium: true,
    business: true,
  },
  {
    name: 'Bantuan Prioritas',
    free: false,
    premium: false,
    business: true,
  },
  {
    name: 'Konsultasi Bisnis',
    free: false,
    premium: false,
    business: true,
  },
  {
    name: 'Server Prioritas',
    free: false,
    premium: false,
    business: true,
  },
]

const packageTypeMap = {
  0: 'Free',
  1: 'Premium',
  2: 'Pro',
  3: 'Plus',
}

const businessPeriod = [
  {
    name: '1-6 Bulan',
    value: 1,
  },
  {
    name: '6-12 Bulan',
    value: 2,
  },
  {
    name: 'Lebih dari 1 tahun',
    value: 3,
  },
  {
    name: 'Lebih dari 3 tahun',
    value: 4,
  },
]

const businessField = [
  {
    name: 'Fashion',
    value: 1,
  },
  {
    name: 'Food & Beverage',
    value: 2,
  },
  {
    name: 'Kecantikan',
    value: 3,
  },
  {
    name: 'Konveksi',
    value: 4,
  },
  {
    name: 'Jasa',
    value: 5,
  },
  {
    name: 'Elektronik',
    value: 6,
  },
  {
    name: 'Kebutuhan sehari-hari',
    value: 7,
  },
  {
    name: 'Lainnya',
    value: 8,
  },
]

const businessType = [
  {
    name: 'Retail',
    value: 1,
  },
  {
    name: 'Produksi Sendiri',
    value: 2,
  },
  {
    name: 'Jasa',
    value: 3,
  },
]

const businessHopes = [
  {
    name: 'Catatan stok yang lebih rapi',
    value: 1,
  },
  {
    name: 'Laporan keuangan yang lebih rapi',
    value: 2,
  },
  {
    name: 'Menghindari kecurangan oleh karyawan',
    value: 3,
  },
]

const businessChannels = [
  {
    name: 'E-Commerce',
    value: 1,
  },
  {
    name: 'Offline',
    value: 2,
  },
  {
    name: 'Online Lainnya (Instagram, Whatsapp)',
    value: 3,
  },
]

const accessMap = {
  // action_page_target
  view_inventory_buyprice: 39,
  view_inventory_stockopname: 29,
  edit_inventory_sellprice: 49,
  add_inbound_transaction: 43,
  edit_inbound_transaction: 44,
  cancel_inbound_transaction: 45,
  add_outbound_transaction: 40,
  edit_outbound_transaction: 41,
  cancel_outbound_transaction: 42,
  edit_outbound_price: 46,
  edit_outbound_discount: 47,
  edit_inbound_price: 48,

  view_outbound_total: 50,
  edit_outbound_status: 51,
  edit_outbound_return: 52,
  add_warehouse_transfer: 53,
  edit_warehouse_transfer_out_status: 54,
  edit_warehouse_transfer_in_status: 55,

  add_debt: 56,
  edit_debt: 57,
  delete_debt: 58,
  add_credit: 59,
  edit_credit: 60,
  delete_credit: 61,

  add_inventory_product: 62,
  edit_inventory_product: 63,
  delete_inventory_product: 64,
  add_inventory_stockopname: 65,

  add_disbursement: 66,

  // waybill
  add_waybill: 68,
  edit_waybill: 69,
  update_status_waybill: 70,
  cancel_waybill: 71,
}

const templateType = [
  {
    name: 'Tanpa Gudang ID',
    value: 1,
  },
  {
    name: 'Dengan Gudang ID',
    value: 2,
  },
]

export default {
  basedate,
  url_contact,
  customerType,
  customerTypeMap,
  accountingType,
  accountingTypeMap,
  mapMenuDesc,
  bank,
  sources,
  categories,
  stockFilterType,
  statuses,
  pricing,
  packageTypeMap,
  businessPeriod,
  businessField,
  businessType,
  businessHopes,
  businessChannels,
  packages,
  accessMap,
  templateType,
}
