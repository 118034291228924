/* eslint-disable import/no-anonymous-default-export */
import forgot from './auth/figure-forgot.png'
import login from './auth/figure-login.png'
import bgbilling from './background/bg-billing.jpg'
import bgtrial from './background/bg-trial.jpg'
import bgupgrade from './background/bg-upgrade.jpg'
import triangle from './background/triangle.png'
import emptyaccountnumber from './empystate/account-number.png'
import emptycreditdebt from './empystate/credit-debt.png'
import emptycustomercategory from './empystate/customer-category.png'
import emptycustomer from './empystate/customer.png'
import emptydashboardpopular from './empystate/dashboard-popular.png'
import emptydashboardunproductive from './empystate/dashboard-unproductive.png'
import emptyemployee from './empystate/employee.png'
import emptyinbound from './empystate/inbound.png'
import emptyincome from './empystate/income.png'
import emptyonlinestore from './empystate/online-store.png'
import emptyoutbound from './empystate/outbound.png'
import emptyoutcome from './empystate/outcome.png'
import emptypricehistory from './empystate/price-history.png'
import emptyproductcategory from './empystate/product-category.png'
import emptyproduct from './empystate/product.png'
import emptyproduction from './empystate/production.png'
import emptyreport from './empystate/report.png'
import emptywaybill from './empystate/waybill.png'
import emptystockopname from './empystate/stock-opname.png'
import emptysupplier from './empystate/supplier.png'
import emptytransactionproduction from './empystate/transaction-production.png'
import emptytransferin from './empystate/transfer-in.png'
import emptytransferout from './empystate/transfer-out.png'
import emptywarehouse from './empystate/warehouse.png'
import emptywaybilldriver from './empystate/waybill-driver.png'
import figureemail from './figure/email.png'
import figurereset from './figure/figure-reset.png'
import figurestripdelivery from './figure/figure-strip-delivery.png'
import figuresuccesstrial from './figure/figure-success-trial.jpg'
import figuretrial from './figure/figure-trial.png'
import figureThankyou from './figure/figure-thankyou.png'
import phoneverification from './figure/phone-verification.png'
import registerwelcome from './figure/register-welcome.png'
import gifuploadfile from './gif/upload-file.gif'
import balance from './icon/icon-balance.png'
import basketsize from './icon/icon-basket-size.png'
import calendarred from './icon/icon-calendar-red.png'
import calendar from './icon/icon-calendar.png'
import cart from './icon/icon-cart.png'
import ccgreen from './icon/icon-cc-green.png'
import ccred from './icon/icon-cc-red.png'
import cc from './icon/icon-cc.png'
import cloth from './icon/icon-cloth.png'
import creditdebt from './icon/icon-creditdebt.png'
import excel from './icon/icon-excel.png'
import finishorange from './icon/icon-finish-orange.png'
import finish from './icon/icon-finish.png'
import google from './icon/icon-google.png'
import graph from './icon/icon-graph.png'
import invoice from './icon/icon-invoice.png'
import linkout from './icon/icon-linkout.png'
import noproduct from './icon/icon-noproduct.png'
import percent from './icon/icon-percent.png'
import picture from './icon/icon-picture.png'
import productbundle from './icon/icon-product-bundle.png'
import productproduction from './icon/icon-product-production.png'
import profitmargin from './icon/icon-profit-margin.png'
import puzzle from './icon/icon-puzzle.png'
import receipt from './icon/icon-receipt.png'
import secure from './icon/icon-secure.png'
import starorange from './icon/icon-star-orange.png'
import start from './icon/icon-start.png'
import tools from './icon/icon-tools.png'
import transaction from './icon/icon-transaction.png'
import truckred from './icon/icon-truck-red.png'
import wallet from './icon/icon-wallet.png'
import invoicestep1 from './invoice/step1.png'
import invoicestep2 from './invoice/step2.png'
import invoicestep3 from './invoice/step3.png'
import invoicestep4 from './invoice/step4.png'
import logocircle from './logo/circle.png'
import logohorizontalwhite from './logo/horizontal-white.png'
import logohorizontal from './logo/horizontal.png'
import logoblue from './logo/logo-blue.png'
import logowhite from './logo/logo-white.png'
import logogram from './logo/logogram.png'
import logovertical from './logo/vertical.png'
import lazada from './marketplace/lazada.png'
import shopee from './marketplace/shopee.png'
import tokopedia from './marketplace/tokopedia.png'
import tiktok from './marketplace/tiktok.png'
import logolazada from './marketplace/logo-lazada.png'
import logoshopee from './marketplace/logo-shopee.png'
import logotokopedia from './marketplace/logo-tokopedia.png'
import logotiktok from './marketplace/logo-tiktok.png'
import onboardbalance from './onboard/balance.png'
import onboardbundle from './onboard/bundle.png'
import onboardcreditdebt from './onboard/creditdebt.png'
import onboardfirstinboundsuccess from './onboard/first-inbound-success.png'
import onboardfirstinbound from './onboard/first-inbound.png'
import onboardfirstoutboundsuccess from './onboard/first-outbound-success.png'
import onboardfirstoutbound from './onboard/first-outbound.png'
import onboardfirstproductsuccess from './onboard/first-product-success.png'
import onboardfirstproduct from './onboard/first-product.png'
import onboardinbound from './onboard/inbound.png'
import onboardinvoice from './onboard/invoice.png'
import onboardoutbound from './onboard/outbound.png'
import onboardproduction from './onboard/production.png'
import onboardstockopname from './onboard/stockopname.png'
import bgReferralBanner from './referral/bg-banner.png'
import refcode from './referral/code.png'
import reffigure from './referral/figure.png'
import refreward from './referral/reward.png'
import refsharebig from './referral/share-big.png'
import refshare from './referral/share.png'
import refsuccess from './referral/success.png'
import upgradehousefree from './upgrade/house-free.png'
import upgradehousepremium from './upgrade/house-premium.png'
import upgradehousepro from './upgrade/house-pro.png'
import upgradepremiumannual from './upgrade/premium-annual.png'
import upgradepremiummonthly from './upgrade/premium-monthly.png'
import upgradeproannual from './upgrade/pro-annual.png'
import upgradepromonthly from './upgrade/pro-monthly.png'
import oclockbannerdashboard from './oclock/banner-dashboard.jpg'
import oclockbannerbilling from './oclock/banner-billing.jpg'
import oclockpopup from './oclock/popup.png'
import oclockbgchoosepackage from './oclock/bg-choose-package.jpg'
import free from './icon/icon-free.png'
import figureContactUs from './figure/figure-contact-us.png'
import figureMailSent from './figure/figure-mail-sent.png'
import emptyExpirationReminder from './empystate/empty-expiration-reminder.png'
import bgVoucher from './background/bg-voucher.png'
import bgVoucherMobile from './background/bg-voucher-mobile.png'
import figurePromotion from './figure/figure-promotion.png'
import iconDiscount from './icon/icon-discount.svg'
import bgVoucher1 from './background/bg-voucher-1.png'
import figureMaxSku from './figure/figure-max-sku.png'
import iconUpgrade from './icon/icon-upgrade.svg'
import iconVisa from './icon/icon-visa.svg'
import iconMastercard from './icon/icon-mastercard.svg'
import iconJcb from './icon/icon-jcb.svg'
import iconAmex from './icon/icon-amex.svg'
import figureCreditCardCvv from './figure/figure-creditCard-cvv.png'
import figurePaymentLoading from './figure/figure-payment-loading.png'
import figurePaymentSuccess from './figure/figure-payment-success.png'
import figurePaymentFailed from './figure/figure-payment-failed.png'
import iconCalendarOutline from './icon/icon-calendary-outline.svg'
import iconWalletMoneyOutline from './icon/icon-wallet-money.svg'
import truckFast from './icon/icon-truck-fast.png'
import bgReferral from './referral/bg-referral.png'
import iconReward3Mo from './referral/reward-3-mo.png'
import iconReward6Mo from './referral/reward-6-mo.png'
import iconReward12Mo from './referral/reward-12-mo.png'
import figureBarcodeConfirmation from './figure/figure-barcode-confirmation.png'
import figureVerifyData from './figure/figure-verify-data.png'
import iconWarningYellow from './icon/icon-warning-yellow.png'
import iconWarningRed from './icon/icon-warning-red.png'
import exitBannerHeader from './closing/exit_banner_header.png'

export default {
  logo: {
    white: logowhite,
    blue: logoblue,
    horizontal: logohorizontal,
    horizontalwhite: logohorizontalwhite,
    logogram,
    vertical: logovertical,
    circle: logocircle,
  },
  icon: {
    noproduct,
    picture,
    basketsize,
    cart,
    graph,
    transaction,
    balance,
    profitmargin,
    receipt,
    tools,
    wallet,
    excel,
    starorange,
    google,
    productproduction,
    productbundle,
    puzzle,
    cloth,
    start,
    finish,
    cc,
    ccgreen,
    ccred,
    creditdebt,
    calendar,
    calendarred,
    invoice,
    finishorange,
    truckred,
    linkout,
    secure,
    percent,
    free,
    discount: iconDiscount,
    upgrade: iconUpgrade,
    visaCC: iconVisa,
    mastercardCC: iconMastercard,
    jcbCC: iconJcb,
    amexCC: iconAmex,
    calendarOutline: iconCalendarOutline,
    walletMoneyOutline: iconWalletMoneyOutline,
    iconWarningYellow,
    iconWarningRed,
    truckFast,
  },
  auth: {
    login,
    forgot,
  },
  background: {
    bgbilling,
    bgtrial,
    triangle,
    bgupgrade,
    bgVoucher,
    bgVoucherMobile,
    bgVoucher1,
  },
  marketplace: {
    tokopedia,
    shopee,
    tiktok,
    lazada,
    logotokopedia,
    logoshopee,
    logotiktok,
    logolazada,
  },
  figure: {
    figuretrial,
    figuresuccesstrial,
    figurestripdelivery,
    registerwelcome,
    figurereset,
    phoneverification,
    figureemail,
    figureContactUs,
    figureMailSent,
    figureThankyou,
    figurePromotion,
    figureMaxSku,
    figureCreditCardCvv,
    figurePaymentLoading,
    figurePaymentSuccess,
    figurePaymentFailed,
    figureBarcodeConfirmation,
    figureVerifyData,
  },
  emptystate: {
    product: emptyproduct,
    production: emptyproduction,
    accountnumber: emptyaccountnumber,
    creditdebt: emptycreditdebt,
    customercategory: emptycustomercategory,
    customer: emptycustomer,
    dashboardpopular: emptydashboardpopular,
    dashboardunproductive: emptydashboardunproductive,
    employee: emptyemployee,
    inbound: emptyinbound,
    income: emptyincome,
    onlinestore: emptyonlinestore,
    outbound: emptyoutbound,
    outcome: emptyoutcome,
    pricehistory: emptypricehistory,
    productcategory: emptyproductcategory,
    stockopname: emptystockopname,
    supplier: emptysupplier,
    transactionproduction: emptytransactionproduction,
    warehouse: emptywarehouse,
    transferin: emptytransferin,
    transferout: emptytransferout,
    report: emptyreport,
    emptyExpirationReminder,
    waybill: emptywaybill,
    wabilldriver: emptywaybilldriver,
  },
  gif: {
    uploadfile: gifuploadfile,
  },
  onboard: {
    balance: onboardbalance,
    bundle: onboardbundle,
    inbound: onboardinbound,
    invoice: onboardinvoice,
    outbound: onboardoutbound,
    production: onboardproduction,
    stockopname: onboardstockopname,
    creditdebt: onboardcreditdebt,
    firstproduct: onboardfirstproduct,
    firstproductsuccess: onboardfirstproductsuccess,
    firstinbound: onboardfirstinbound,
    firstinboundsuccess: onboardfirstinboundsuccess,
    firstoutbound: onboardfirstoutbound,
    firstoutboundsuccess: onboardfirstoutboundsuccess,
  },
  upgrade: {
    housepremium: upgradehousepremium,
    housepro: upgradehousepro,
    housefree: upgradehousefree,
    premiumannual: upgradepremiumannual,
    premiummonthly: upgradepremiummonthly,
    proannual: upgradeproannual,
    promonthly: upgradepromonthly,
  },
  invoice: {
    step1: invoicestep1,
    step2: invoicestep2,
    step3: invoicestep3,
    step4: invoicestep4,
  },
  referral: {
    bg: bgReferralBanner,
    code: refcode,
    figure: reffigure,
    reward: refreward,
    sharebig: refsharebig,
    share: refshare,
    success: refsuccess,
    bgReferral,
    iconReward3Mo,
    iconReward6Mo,
    iconReward12Mo,
  },
  oclock: {
    bannerdashboard: oclockbannerdashboard,
    bannerbilling: oclockbannerbilling,
    popup: oclockpopup,
    bgchoosepackage: oclockbgchoosepackage,
  },
  closing: {
    exitBannerHeader: exitBannerHeader
  }
}
