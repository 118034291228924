import {
  CFormGroup,
  CInputGroup,
  CInvalidFeedback,
  CLabel,
  CSelect,
} from '@coreui/react'
import { Flex, Span } from 'assets/components/components'
import { colors } from 'assets/styles/styles'
import React from 'react'

const Select = ({
  label,
  id,
  name,
  options,
  placeholder,
  wrapperStyle,
  labelStyle,
  nameKey = 'name',
  valueKey = 'value',
  error,
  className = 'o-select',
  value,
  onChange,
  required,
  tooltip,
  noSelection,
  disabled,
  ...props
}) => {
  return (
    <CFormGroup style={wrapperStyle}>
      {label && (
        <CLabel htmlFor={id} style={labelStyle}>
          <Flex justifyContent={'center'}>
            {label}{' '}
            {required ? (
              <Span color={colors?.red} margin={'0 0 0 4px'}>
                *
              </Span>
            ) : null}{' '}
            {tooltip ? <Span margin={'0 0 0 4px'}>{tooltip}</Span> : null}
          </Flex>
        </CLabel>
      )}
      <CInputGroup>
        <CSelect
          {...props}
          custom
          invalid={error ? true : false}
          value={value}
          id={id}
          name={name || id}
          style={
            disabled
              ? {
                  backgroundColor: colors?.GRAY_100,
                }
              : value != ''
              ? {}
              : { color: '#ccc' }
          }
          className={className}
          onChange={(e) => {
            if (options[e.target.selectedIndex - 1])
              e.target.object = options[e.target.selectedIndex - 1]
            onChange(e)
          }}
          required={required}
          disabled={disabled}
        >
          <option value="" disabled>
            {placeholder}
          </option>

          {options.map((g, i) => {
            let label = g[nameKey]

            if (name === 'category') {
              label = `${g[nameKey]}${
                g?.ppn_detail && g?.ppn_detail !== undefined
                  ? ` - PPn ${g?.ppn_detail?.ppn_master?.value}%`
                  : ''
              }`
            } else if (name === 'ppn') {
              label = `${g[nameKey]}${
                g?.value > 0 ? ` - PPn ${g?.value}%` : ''
              }`
            }

            return (
              <option key={i} value={g[valueKey]}>
                {label}
              </option>
            )
          })}
        </CSelect>
      </CInputGroup>
      {error ? (
        <CInvalidFeedback style={{ display: 'block', marginLeft: 15 }}>
          {error}
        </CInvalidFeedback>
      ) : null}
    </CFormGroup>
  )
}

export default Select
