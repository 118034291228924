import { CCol, CRow } from '@coreui/react'
import React from 'react'
import { Image, Relative, Text } from '../../../assets/components/components'
import images from '../../../assets/images'
import { colors } from '../../../assets/styles/styles'
import master from '../../../utils/master'
import Checkbox from '../../Input/Checkbox'
import Select from '../../Input/Select'

const First = ({ form, errors, onChange, types, setTypes }) => {
  return (
    <div>
      <CRow>
        <CCol xs="12" sm="4">
          <Image src={images?.figure?.registerwelcome} width="100%" />
        </CCol>
        <CCol xs="12" sm="6">
          <Text
            fontWeight="bold"
            fontSize="16px"
            margin="0 0 20px"
            color={colors?.primary}
          >
            Selamat Datang!
          </Text>
          <Text
            fontWeight="bold"
            fontSize="15px"
            margin="0 0 20px"
            color={colors?.primary}
          >
            Bantu Onstock memahami bisnismu lebih dalam untuk pengalaman bisnis
            yang lebih baik, yuk mulai!
          </Text>
        </CCol>
      </CRow>
      <Select
        label="Sudah berapa lama bisnismu berjalan?"
        name="period"
        id="period"
        value={form?.period}
        error={errors?.period}
        options={master.businessPeriod}
        onChange={onChange}
      />
      <Select
        label="Apa bidang bisnismu?"
        name="field"
        id="field"
        value={form?.field}
        error={errors?.field}
        options={master.businessField}
        onChange={onChange}
      />

      <Text margin="0 0 10px">Apa jenis bisnismu?</Text>
      {types.map((g, i) => (
        <Relative key={i} margin="0 0 10px">
          <Checkbox
            color="primary"
            checked={g.checked}
            label={g.name}
            onChange={() => {
              let newdata = [...types]
              newdata[i].checked = !newdata[i].checked
              setTypes(newdata)
            }}
          />
        </Relative>
      ))}
      {errors?.types ? <Text color={colors?.red}>{errors.types}</Text> : null}
    </div>
  )
}

export default First
