const NODE_ENV = process.env.REACT_APP_NODE_ENV || 'staging'

const BASE_URL = process.env.REACT_APP_BASE_URL || ''

const LANDING_WEB_URL = process.env.REACT_APP_LANDING_WEB_URL || ''
const ADMIN_WEB_URL = process.env.REACT_APP_ADMIN_WEB_URL || ''
const SURVEY_FORM_URL = process.env.REACT_APP_SURVEY_FORM_URL || ''
const ONSTOCK_CS_PHONE_NUMBER = 6289510848960 || ''

const SECRET_GOOGLE_API_KEY = process.env.REACT_APP_SECRET_GOOGLE_API_KEY || ''

const PUBLIC_GOOGLE_CLIENT_ID =
  process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID || ''
const ONSTOCK_STORAGE = process.env.REACT_APP_ONSTOCK_STORAGE || ''

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || ''

// const BRAZE_API_KEY = process.env.REACT_APP_BRAZE_API_KEY || ''
// const BRAZE_SDK_ENDPOINT = process.env.REACT_APP_BRAZE_SDK_ENDPOINT || ''
// const BRAZE_USER_IDENTIFIER_PREFIX =
//   process.env.REACT_APP_BRAZE_USER_IDENTIFIER_PREFIX || ''

const OCLOCK_BANNER = process.env.REACT_APP_OCLOCK_BANNER || ''

const XENDIT_PUBLIC_KEY = process.env.REACT_APP_XENDIT_PUBLIC_KEY || ''

export {
  NODE_ENV,
  BASE_URL,
  LANDING_WEB_URL,
  ADMIN_WEB_URL,
  SURVEY_FORM_URL,
  SECRET_GOOGLE_API_KEY,
  PUBLIC_GOOGLE_CLIENT_ID,
  ONSTOCK_STORAGE,
  SENTRY_DSN,
  // BRAZE_API_KEY,
  // BRAZE_SDK_ENDPOINT,
  // BRAZE_USER_IDENTIFIER_PREFIX,
  OCLOCK_BANNER,
  XENDIT_PUBLIC_KEY,
  ONSTOCK_CS_PHONE_NUMBER,
}
