import { baseURL } from '../../../utils/apihost'
import { fetchGet, fetchPost, generateQueryParams } from '../../../utils/format'

export default {
  async getProfile(token) {
    let responseJSON = await fetchGet(baseURL + '/user/profile', token)
    return responseJSON
  },

  async getMarketplaces(token) {
    let responseJSON = await fetchGet(baseURL + '/marketplace/listScope', token)
    return responseJSON
  },

  async getCouriers(token) {
    let responseJSON = await fetchGet(baseURL + '/courier/listScope', token)
    return responseJSON
  },

  async getResiSettings(token) {
    let responseJSON = await fetchGet(baseURL + '/user/getResiSetting', token)
    return responseJSON
  },


  async getInvoiceSettings(token) {
    let responseJSON = await fetchGet(baseURL + '/user/getInvoiceSetting', token)
    return responseJSON
  },

  async updateResiSettings(value, token) {
    let form = JSON.stringify({
      value,
    })
    let responseJSON = await fetchPost(
      baseURL + '/user/updateResiSetting',
      form,
      token,
    )
    return responseJSON
  },
  async updateInvoiceSettings(value, token) {
    let form = JSON.stringify({
      value,
    })
    let responseJSON = await fetchPost(
      baseURL + '/user/updateInvoiceSetting',
      form,
      token,
    )
    return responseJSON
  },
    async setInvoiceLogo(value, token) {
      let FormData = require('form-data')
      let form = new FormData()
      form.append('invoice_logo', value?.image)
      let responseJSON = await fetchPost(
        baseURL + '/store/setInvoiceLogo',
        form,
        token,
        false,
      )
    return responseJSON
  },
  async getMethods(token) {
    let responseJSON = await fetchGet(
      baseURL + '/paymentmethod/listScope',
      token,
    )
    return responseJSON
  },

  async updateMarketplaces(marketplace_ids, token) {
    let form = JSON.stringify({
      marketplace_ids,
    })
    let responseJSON = await fetchPost(
      baseURL + '/marketplace/setScope',
      form,
      token,
    )
    return responseJSON
  },

  async updateCouriers(courier_ids, token) {
    let form = JSON.stringify({
      courier_ids,
    })
    let responseJSON = await fetchPost(
      baseURL + '/courier/setScope',
      form,
      token,
    )
    return responseJSON
  },

  async updateMethods(payment_method_ids, token) {
    let form = JSON.stringify({
      payment_method_ids,
    })
    let responseJSON = await fetchPost(
      baseURL + '/paymentmethod/setScope',
      form,
      token,
    )
    return responseJSON
  },

  async updateProfile(name, phone_number, address, img_logo, token) {
    let FormData = require('form-data')
    let form = new FormData()
    form.append('name', name)
    form.append('phone_number', phone_number)
    form.append('address', address)
    form.append('img_logo', img_logo)
    let responseJSON = await fetchPost(
      baseURL + '/store/setProfile',
      form,
      token,
      false,
    )
    return responseJSON
  },

  async updateUser(name, token) {
    let form = JSON.stringify({
      name,
    })
    let responseJSON = await fetchPost(
      baseURL + '/user/doChangeProfile',
      form,
      token,
    )
    return responseJSON
  },

  async updateCustomFields(
    custom_column_1_value,
    custom_column_2_value,
    custom_column_3_value,
    token,
  ) {
    let form = JSON.stringify({
      custom_column_1_value,
      custom_column_2_value,
      custom_column_3_value,
    })
    let responseJSON = await fetchPost(
      baseURL + '/store/setCustomField',
      form,
      token,
    )
    return responseJSON
  },

  async updatePassword(
    old_password,
    new_password,
    confirmation_password,
    token,
  ) {
    let form = JSON.stringify({
      old_password,
      new_password,
      confirmation_password,
    })
    let responseJSON = await fetchPost(
      baseURL + '/user/doChangePassword',
      form,
      token,
    )
    return responseJSON
  },

  async getResetToken(token) {
    let responseJSON = await fetchGet(baseURL + '/getResetToken', token)
    return responseJSON
  },

  async resetData(verification_token, token) {
    let form = JSON.stringify({
      verification_token,
    })
    let responseJSON = await fetchPost(baseURL + '/resetdata', form, token)
    return responseJSON
  },

  async addDeviceToken(device_token, token) {
    let form = JSON.stringify({
      device_token,
    })
    let responseJSON = await fetchPost(
      baseURL + '/user/registerDeviceToken',
      form,
      token,
    )
    return responseJSON
  },

  async removeDeviceToken(device_token, token) {
    let form = JSON.stringify({
      device_token,
    })
    let responseJSON = await fetchPost(
      baseURL + '/user/destroyDeviceToken',
      form,
      token,
    )
    return responseJSON
  },

  async getExpiredReminder(query, token) {
    query = await generateQueryParams(query, {})
    let responseJSON = await fetchGet(
      baseURL + `/expiredheader/get?${query}`,
      token,
    )
    return responseJSON
  },

  async deleteExpiredReminder(data, token) {
    const form = JSON.stringify(data)
    let responseJSON = await fetchPost(
      baseURL + `/expiredheader/doDelete`,
      form,
      token,
    )
    return responseJSON
  },

  async getPpn(query, token) {
    query = await generateQueryParams(query, {})
    let responseJSON = await fetchGet(baseURL + `/ppn/get?${query}`, token)
    return responseJSON
  },

  async deletePpn(data, token) {
    const form = JSON.stringify(data)
    let responseJSON = await fetchPost(baseURL + `/ppn/doDelete`, form, token)
    return responseJSON
  },

  async checkCreatePpn(token) {
    let responseJSON = await fetchGet(baseURL + `/ppn/checkAddppn`, token)
    return responseJSON
  },

  async getOnlineInvoicePaymentMethod(token) {
    let responseJSON = await fetchGet(
      baseURL + '/subscriptionpaymentmethod/listScope',
      token,
    )
    return responseJSON
  },

  async updateOnlineInvoicePaymentMethod(
    subscription_payment_method_ids,
    token,
  ) {
    let form = JSON.stringify({
      subscription_payment_method_ids,
    })
    let responseJSON = await fetchPost(
      baseURL + '/subscriptionpaymentmethod/setScope',
      form,
      token,
    )
    return responseJSON
  },
}
