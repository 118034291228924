import {
  CFormGroup,
  CInput,
  CInputGroup,
  CInvalidFeedback,
  CLabel,
  CValidFeedback,
} from '@coreui/react'
import React from 'react'
import {
  formatDecimalMoney,
  formatNumberMoney,
  numberAndDotOnly,
  numberAndMinus,
  numberOnly,
  phoneOnly,
} from '../../utils/format'
import { colors } from 'assets/styles/styles'

const Input = React.forwardRef(
  (
    {
      label,
      id,
      name,
      append,
      prepend,
      wrapperStyle,
      labelStyle,
      error,
      invalid,
      type = 'text',
      number = false,
      numberMinus = false,
      percent = false,
      phone = false,
      currency = false,
      decimal = false,
      onChange,
      style,
      value,
      required,
      prependOnClick,
      className = 'o-input',
      success,
      description,
      disabled,
      ...props
    },
    ref,
  ) => {
    if (currency) {
      value = formatNumberMoney(value || '')
    }
    return (
      <CFormGroup style={wrapperStyle}>
        {label && (
          <CLabel htmlFor={id} style={labelStyle}>
            {label} {required && <span className="form-star-required">*</span>}
          </CLabel>
        )}
        <CInputGroup>
          {prepend && <div className="o-input-prepend">{prepend}</div>}
          <CInput
            {...props}
            type={type}
            id={id}
            name={name || id}
            invalid={error ? true : false}
            innerRef={ref}
            value={value}
            onChange={(e) => {
              if (number) {
                e.target.value = numberOnly(e.target.value)
              }
              if (percent) {
                e.target.value = numberAndDotOnly(e.target.value)
              }
              if (phone) {
                e.target.value = phoneOnly(e.target.value)
              }
              if (numberMinus) {
                e.target.value = numberAndMinus(e.target.value)
              }
              if (currency) {
                e.target.value = numberOnly(e.target.value)
                if (e.target.value != '')
                  e.target.value = parseInt(
                    e.target.value.split('.').join(''),
                  ).toString()
                else e.target.value = ''
              }
              if (decimal) {
                e.target.value = formatDecimalMoney(e.target.value || '')
              }
              onChange(e)
            }}
            className={className}
            required={required}
            style={
              disabled
                ? {
                    ...style,
                    color: colors?.GRAY_500,
                    backgroundColor: colors?.GRAY_100,
                  }
                : style
            }
            disabled={disabled}
          />
          {append && (
            <div
              className="o-input-append"
              onClick={() => {
                prependOnClick && prependOnClick()
              }}
            >
              {append}
            </div>
          )}
          {percent && !append && <div className="o-input-append">%</div>}
        </CInputGroup>
        {error ? (
          <CInvalidFeedback style={{ display: 'block', marginLeft: 15 }}>
            {error}
          </CInvalidFeedback>
        ) : null}
        {success ? (
          <CValidFeedback style={{ display: 'block', marginLeft: 15 }}>
            {success}
          </CValidFeedback>
        ) : null}
        {description ? (
          <CLabel className={'description'}>{description}</CLabel>
        ) : null}
      </CFormGroup>
    )
  },
)

export default Input
