import { CModal, CModalBody } from '@coreui/react'
import React, { useRef, useState } from 'react'
import { GoLocation } from 'react-icons/go'
import { useDispatch, useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'
import { Flex, Relative, Text } from '../../assets/components/components'
import { colors } from '../../assets/styles/styles'
import { deepCopy } from '../../utils/format'
import ajax from '../../views/Warehouse/Location/ajax'
import Button from '../Button/Button'
import ErrorForm from '../Error/ErrorForm'
import Input from '../Input/Input'
import Location from '../Input/Location'

const inputs = {
  name: '',
  address: '',
}

const err = {
  name: '',
  address: '',
}

const ModalOnboardingLocation = ({ open }) => {
  const [form, setForm] = useState(deepCopy(inputs))
  const [errors, setErrors] = useState({ ...err })
  const [error, setError] = useState('')
  const [map, setMap] = useState(false)
  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    address: '',
  })
  const [done, setDone] = useState(false)
  const mapInput = useRef(null)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)

  const onChange = (e) => {
    const value =
      e.target.type == 'checkbox' ? e.target.checked : e.target.value
    setForm({ ...form, [e.target.name]: value })
  }

  const [state, submit] = useAsyncFn(async (form, location, token, user) => {
    const res = await ajax.add(
      form?.name,
      form?.address,
      location?.latitude,
      location?.longitude,
      location?.address,
      token,
    )
    if (!res.success) {
      setError(res.message)
      return false
    }

    // set finish onboarding
    await ajax.updateFinishOnboarding(user?.user_id, token)
    //

    await setDone(true)
    await dispatch({
      type: 'update',
      refetchLocation: true,
      onboardingLocation: false,
      modalRequestDemo: true,
    })

    dispatch({
      type: 'addtoast',
      toast: {
        show: true,
        title: 'Berhasil',
        message: res.message,
      },
    })
  }, [])

  const checkClosed = () => {
    if (!done) dispatch({ type: 'update', onboardingLocation: true })
  }

  return (
    <>
      <CModal
        show={open}
        closeOnBackdrop={false}
        onClose={() => dispatch({ type: 'update', onboardingLocation: false })}
        onClosed={checkClosed}
      >
        <ErrorForm error={error} />
        <CModalBody>
          <Relative>
            <Text fontWeight="bold" fontSize="16px" margin="0 0 20px">
              Masukkan Lokasi Gudang Kamu
            </Text>
            <Input
              label="Nama Gudang"
              id="name"
              required
              value={form.name}
              error={errors.name}
              onChange={onChange}
            />

            <Input
              prepend={<GoLocation size={20} color={colors?.primary} />}
              label="Lokasi"
              id="location"
              name="location"
              placeholder="Pilih Titik Lokasi"
              value={location.address}
              onFocus={() => {
                setMap(true)
                mapInput.current && mapInput.current.blur()
              }}
              ref={mapInput}
              prependOnClick={() => setMap(true)}
              style={{ paddingLeft: 40 }}
            />
            <Input
              label="Rincian Alamat"
              id="address"
              value={form.address}
              error={errors.address}
              onChange={onChange}
            />
            <Flex justifyContent="flex-end" margin="20px 0 0">
              <Button
                color="primary"
                padding="10px 24px"
                radius="20px"
                loading={state.loading}
                onClick={() => submit(form, location, token, user)}
              >
                Simpan
              </Button>
            </Flex>
          </Relative>
        </CModalBody>
      </CModal>

      <Location
        open={map}
        close={() => setMap(false)}
        value={location}
        onChange={setLocation}
      />
    </>
  )
}

export default ModalOnboardingLocation
