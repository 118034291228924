import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react'
import React from 'react'
import { useSelector } from 'react-redux'

const Toasts = () => {
  const toasts = useSelector((state) => state.toasts)
  return (
    <CToaster position={'top-right'} key={'toaster'}>
      {toasts.map((g, i) => (
        <CToast
          key={'toast' + i}
          show={g.show}
          autohide={5000}
          className={g.color || 'primary'}
        >
          <CToastHeader closeButton>{g.title}</CToastHeader>
          <CToastBody style={{ whiteSpace: 'pre-wrap' }}>
            {g.message}
          </CToastBody>
        </CToast>
      ))}
    </CToaster>
  )
}

export default Toasts
