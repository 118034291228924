import React, { useRef, useState } from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import { defaultLocation } from './MapsUtilities'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete'
import { Absolute } from '../../assets/components/components'

const Maps = ({
  defaultZoom = 15,
  defaultCenter = defaultLocation,
  markers = [],
  onClick,
  ...props
}) => {
  const map = useRef(null)

  const move = async (data) => {
    let res = await geocodeByPlaceId(data?.value?.place_id)
    if (res?.length > 0) {
      let latitude = res[0]?.geometry?.location?.lat()
      let longitude = res[0]?.geometry?.location?.lng()
      let obj = {
        latitude,
        longitude,
        lat: latitude,
        lng: longitude,
        latLng: {
          lat: res[0]?.geometry?.location?.lat,
          lng: res[0]?.geometry?.location?.lng,
        },
      }
      map.current.panTo(obj)
      onClick(obj)
    }
  }

  return (
    <GoogleMap
      defaultZoom={defaultZoom}
      defaultCenter={defaultCenter}
      key="map"
      ref={map}
      onClick={onClick}
      defaultOptions={{ mapTypeControl: false }}
      {...props}
    >
      <Absolute top="12px" left="12px" width="60%">
        <GooglePlacesAutocomplete
          autocompletionRequest={{ componentRestrictions: { country: ['id'] } }}
          selectProps={{ onChange: (e) => move(e) }}
        />
      </Absolute>

      {markers.map((item, index) => (
        <Marker
          key={index}
          position={{
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          }}
        />
      ))}
    </GoogleMap>
  )
}

export default withScriptjs(withGoogleMap(Maps))
