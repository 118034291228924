import { CCol, CRow } from '@coreui/react'
import React from 'react'
import { Image, Text } from '../../../assets/components/components'
import images from '../../../assets/images'
import { colors } from '../../../assets/styles/styles'
import master from '../../../utils/master'
import Input from '../../Input/Input'
import InputImage from '../../Input/InputImage'
import Select from '../../Input/Select'

const Third = ({ form, errors, onChange, setForm, types, setTypes }) => {
  return (
    <div>
      <CRow>
        <CCol xs="12" sm="4">
          <Image src={images?.figure?.registerwelcome} width="100%" />
        </CCol>
        <CCol xs="12" sm="6">
          <Text
            fontWeight="bold"
            fontSize="16px"
            margin="0 0 20px"
            color={colors?.primary}
          >
            Selamat Datang!
          </Text>
          <Text
            fontWeight="bold"
            fontSize="15px"
            margin="0 0 20px"
            color={colors?.primary}
          >
            Bantu Onstock memahami bisnismu lebih dalam untuk pengalaman bisnis
            yang lebih baik, yuk mulai!
          </Text>
        </CCol>
      </CRow>

      <Text fontWeight="bold" fontSize="16px" margin="0 0 15px">
        Tentang Perusahaan
      </Text>
      <CRow>
        <CCol xs="12" sm="6">
          <InputImage
            placeholder="Logo Perusahaan (Opsional)"
            value={form?.image}
            onChange={(e) => {
              if (e.target.files?.length > 0)
                setForm({ ...form, image: e.target.files[0] })
            }}
          />
        </CCol>
        <CCol xs="12" sm="6">
          <Input
            label="Nama Perusahaan"
            id="name"
            required
            value={form.name}
            error={errors.name}
            onChange={onChange}
          />
        </CCol>
      </CRow>
      <Input
        label="No. Telepon"
        id="phone"
        value={form.phone}
        error={errors.phone}
        onChange={onChange}
        required
        prepend={<Text>+62</Text>}
        style={{ paddingLeft: 44, paddingBottom: 9 }}
      />
      <Input
        label="Alamat"
        id="address"
        value={form.address}
        error={errors.address}
        onChange={onChange}
      />
      <Select
        label="Bidang Usaha"
        name="businesstype"
        id="businesstype"
        value={form?.businesstype}
        error={errors?.businesstype}
        options={master?.businessField}
        onChange={onChange}
        required
      />
    </div>
  )
}

export default Third
