import 'firebase/analytics'
import 'firebase/messaging'
import React, { useEffect, useState } from 'react'
import 'react-dates/lib/css/_datepicker.css'
import Geocode from 'react-geocode'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import './assets/scss/style.scss'
import ModalOnboardingLocation from './components/Onboarding/ModalOnboardingLocation'
import ModalOnboardingProfile from './components/Onboarding/ModalOnboardingProfile'
import ModalLoading from './components/Spinner/ModalLoading'
import PageLoading from './components/Spinner/PageLoading'
import Toasts from './components/Toast/Toasts'
import icons from './containers/_nav_icon'
import routes from './routes'
import { googleapikey } from './utils/key'
import moment from 'moment'
import { env } from './utils/apihost'
import ModalRequestDemo from 'components/Modal/ModalRequestDemo'
import { setupFCM } from './utils/pushnotification.js'

const loading = <PageLoading />
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

const App = () => {
  const [ready, setReady] = useState(false)
  const dispatch = useDispatch()
  const authenticated = useSelector((state) => state.authenticated)
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)
  const loading = useSelector((state) => state.loading)
  const onboardingProfile = useSelector((state) => state.onboardingProfile)
  const onboardingLocation = useSelector((state) => state.onboardingLocation)
  const isRequestDemoVisible = useSelector((state) => state.modalRequestDemo)

  const fetchData = async () => {
    let login_data = localStorage.getItem('login_data')
    if (!login_data) {
      dispatch({ type: 'update', authenticated: false })
      setReady(true)
      return false
    }

    login_data = JSON.parse(login_data)
    if (!login_data?.user?.store?.store_category_id) {
      dispatch({ type: 'logout' })
      return false
    }

    let menus = await localStorage.getItem('menus')

    if (menus) {
      menus = await JSON.parse(menus)

      menus = menus.map((g) => {
        return {
          ...g,
          icon: icons[g.icon_name] || '',
        }
      })
    } else menus = []

    let menusObj = await localStorage.getItem('menusObj')

    if (menusObj) {
      menusObj = await JSON.parse(menusObj)
    } else menusObj = {}

    dispatch({
      type: 'update',
      ...login_data,
      menus,
      menusObj,
      authenticated: true,
    })
    setReady(true)
  }

  const onCloseModal = (type) => {
    if (type === 'request-demo') {
      // dispatch({ type: 'update', modalRequestDemo: false })

      let login_data = localStorage.getItem('login_data')
      login_data = JSON.parse(login_data)
      // if (login_data?.isNewUser) {
      //   dispatch({ type: 'update', is_oclock_campaign_active: true })
      // }
      dispatch({
        type: 'update',
        is_oclock_campaign_active: login_data?.isNewUser,
        modalRequestDemo: false,
      })
    }
  }

  useEffect(() => {
    fetchData()
    Geocode.setApiKey(googleapikey)
    if(token) {
      setupFCM(token)
    }
  }, [])

  if (ready)
    return (
      <>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              {routes.map((g, i) => (
                <Route
                  key={i}
                  path={g.path}
                  name={g.name}
                  exact={g.exact}
                  render={(props) => {
                    if (g.public) return <g.component {...g} {...props} />
                    if (authenticated) {
                      if (g.noLayout) return <g.component {...g} {...props} />
                      return <TheLayout {...g} {...props} />
                    }
                    return <Redirect to="/login" />
                  }}
                />
              ))}
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>

        <Toasts />
        <ModalLoading open={loading} />
        <ModalOnboardingProfile open={onboardingProfile} />
        <ModalOnboardingLocation open={onboardingLocation} />
        <ModalRequestDemo
          visibility={isRequestDemoVisible}
          onClose={() => onCloseModal('request-demo')}
        />
      </>
    )
  return loading
}

export default App
