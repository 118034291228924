import React from 'react'
import {
  ButtonLinkStyled,
  ButtonStyled,
} from '../../assets/components/components'
import Spinner from '../Spinner/Spinner'

const Button = ({
  block,
  rounded,
  uppercase,
  color,
  radius,
  padding,
  children,
  width,
  margin,
  disabled,
  onClick,
  border,
  backgroundColor,
  textColor,
  loading,
  loadingComponent,
  loadingMargin,
  loadingIsWhite = true,
  link = false,
  height,
  ...props
}) => {
  let Component = link ? ButtonLinkStyled : ButtonStyled
  return (
    <Component
      {...props}
      disabled={disabled}
      uppercase={uppercase}
      radius={radius}
      rounded={rounded}
      padding={padding}
      color={color}
      block={block}
      width={width}
      margin={margin}
      onClick={disabled || loading ? () => {} : onClick}
      border={border}
      backgroundColor={backgroundColor}
      textColor={textColor}
      height={height}
    >
      {loading
        ? loadingComponent || (
            <Spinner
              name="threebounce"
              size={26}
              white={loadingIsWhite}
              margin={loadingMargin || '-5px auto'}
            />
          )
        : children}
    </Component>
  )
}

Button.defaultProps = {
  block: false,
  rounded: false,
  color: '',
  radius: '4px',
  className: '',
  uppercase: false,
  padding: '8px 12px',
  width: 'auto',
  margin: '0',
  disabled: false,
}

export default Button
