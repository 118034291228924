import { baseURL } from '../../../utils/apihost'
import { fetchGet, fetchPost } from '../../../utils/format'

export default {
  async get(page, search, token) {
    let responseJSON = await fetchGet(
      baseURL + `/location/get?page=${page}&keyword=${search}`,
      token,
    )
    return responseJSON
  },

  async getDeleted(page, token) {
    let responseJSON = await fetchGet(
      baseURL + `/location/getDeleted?page=${page}`,
      token,
    )
    return responseJSON
  },

  async add(name, address, latitude, longitude, map_address, token) {
    let form = JSON.stringify({
      name,
      address,
      latitude,
      longitude,
      map_address,
    })
    let responseJSON = await fetchPost(baseURL + '/location/doAdd', form, token)
    return responseJSON
  },

  async update(
    location_id,
    name,
    address,
    latitude,
    longitude,
    map_address,
    token,
  ) {
    let form = JSON.stringify({
      location_id,
      name,
      address,
      latitude,
      longitude,
      map_address,
    })
    let responseJSON = await fetchPost(
      baseURL + '/location/doEdit',
      form,
      token,
    )
    return responseJSON
  },

  async remove(location_id, token) {
    let form = JSON.stringify({
      location_id,
    })
    let responseJSON = await fetchPost(
      baseURL + '/location/doDelete',
      form,
      token,
    )
    return responseJSON
  },

  async restore(location_id, token) {
    let form = JSON.stringify({
      location_id,
    })
    let responseJSON = await fetchPost(
      baseURL + '/location/doRestore',
      form,
      token,
    )
    return responseJSON
  },

  async updateFinishOnboarding(userId, token) {
    let form = JSON.stringify({
      user_id: userId,
    })
    let responseJSON = await fetchPost(
      baseURL + '/user/setFinishOnboarding',
      form,
      token,
    )
    return responseJSON
  },
}
