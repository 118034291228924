import { createStore } from 'redux'
import { checkToken, unregisterToken } from '../utils/pushnotification'

const initialState = {
  sidebarShow: 'responsive',
  notificationShow: false,
  messageShow: false,
  darkMode: false,
  token: '',
  user: {},
  isNewUser: false,
  ready: false,
  authenticated: false,
  loading: false,
  locations: [],
  activeLocation: 0,
  menus: [],
  menusObj: {},
  success: {
    open: false,
    message: '',
    icon: '',
    buttonOnPress: () => {},
  },
  toasts: [
    {
      show: false,
      title: '',
      message: '',
      color: 'primary',
    },
  ],
  breadcrumb: [],
  modalLogout: false,
  onboardingProfile: false,
  onboardingLocation: false,
  modalRequestDemo: false,
  refreshProduct: false,
  refetchLocation: false,
  is_oclock_campaign_active: false,
  is_after_login: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'update':
      return { ...state, ...rest }
    case 'login':
      localStorage.setItem(
        'login_data',
        JSON.stringify({
          user: rest.user,
          token: rest.token,
          isNewUser: rest.isNewUser,
        }),
      )

      checkToken(rest.token)

      return {
        ...state,
        user: rest.user,
        token: rest.token,
        isNewUser: rest.isNewUser,
        authenticated: true,
      }

    case 'logout':
      unregisterToken(state.token)

      localStorage.clear()
      return initialState

    case 'loading':
      return {
        ...state,
        loading: true,
      }

    case 'unloading':
      return {
        ...state,
        loading: false,
      }

    case 'addtoast':
      return {
        ...state,
        toasts: [...state.toasts, rest.toast],
      }

    default:
      return state
  }
}

const store = createStore(changeState)

export default store
