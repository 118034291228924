import firebase from 'firebase/app'
import ajaxProfile from '../views/Settings/Settings/ajax'
import { firebaseConfig } from './key'
import moment from 'moment'

export async function setupFCM(token) {
  let initializedFirebaseApp = ''

  if (firebase.apps?.length > 0) {
    initializedFirebaseApp = firebase.app() // if already initialized, use that one
  } else {
    initializedFirebaseApp = firebase.initializeApp(firebaseConfig)
  }

  let messaging = ''
  // we need to check if messaging is supported by the browser
  if (firebase.messaging.isSupported()) {
    messaging = initializedFirebaseApp.messaging()

    try {
      const res = await messaging.requestPermission()
      const device_token = await messaging.getToken()

      // send to server
      registerToken(device_token, token)
    } catch (err) {
      if (err.code === 'messaging/token-unsubscribe-failed') setupFCM(token)
    }

    messaging.onMessage((data) => {
      const notification = new Notification(data?.notification?.title, {
        body: data?.notification?.body,
      })
    })
  }

  if (firebase.analytics.isSupported()) {
    firebase.analytics(initializedFirebaseApp)
  }
}

export async function registerToken(device_token, token) {
  const res = await ajaxProfile.addDeviceToken(device_token, token)
  const deviceToken = {
    token: device_token,
    expired: moment().add(7, 'd').format('YYYY-MM-DD HH:mm:ss'),
  }
  localStorage.setItem('device_token', JSON.stringify(deviceToken))
}


export async function checkToken(token) {
  let deviceToken = localStorage.getItem('device_token')
  let res = { success: true }

  if (!deviceToken) {
    res = await setupFCM(token)
  } else {
    deviceToken = JSON.parse(deviceToken)
    const currentTime = moment()
    const expired = moment(deviceToken.expired)
    const isPast = expired.isSameOrBefore(currentTime)
    if (isPast) {
      await unregisterToken()

      res = setupFCM(token)
    }
  }
  return res
}

export async function unregisterToken(token) {
  let device_token = localStorage.getItem('device_token')

  if (device_token) {
    const res = await ajaxProfile.removeDeviceToken(device_token, token)
    localStorage.removeItem('device_token')
  }
}
