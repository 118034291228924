import React from 'react'
import { CAlert } from '@coreui/react'

const ErrorForm = ({ error, ...props }) => {
  if (error)
    return (
      <CAlert
        color="danger"
        closeButton
        style={{
          marginLeft: '1rem',
          marginRight: '1rem',
          marginTop: '1rem',
          marginBottom: '0.1rem',
        }}
        {...props}
      >
        {error}
      </CAlert>
    )
  return null
}

export default ErrorForm
