import { baseURL } from '../../utils/apihost'
import { fetchPost } from '../../utils/format'

export default {
  async createReportRequest(data, token) {
    let form = JSON.stringify(data)
    let responseJSON = await fetchPost(
      baseURL + '/reporting/createReportRequest',
      form,
      token,
    )
    return responseJSON
  },

  async createRequestDemo(phone_number, description, token) {
    let form = JSON.stringify({ phone_number, description })
    let responseJSON = await fetchPost(
      baseURL + '/requestdemo/doAdd',
      form,
      token,
    )
    return responseJSON
  },
}
