import { lazy } from 'react'

const MainWaybill = lazy(() => import('views/Waybill/Main'))
const DetailWaybill = lazy(() => import('views/Waybill/Detail'))
const FormWaybill = lazy(() => import('views/Waybill/Form'))
const WaybillDriver = lazy(() => import('views/Waybill/Driver/Main'))
const WaybillDriverWaybill = lazy(() => import('views/Waybill/Driver/Waybill'))
const PrintWaybillDetailPage = lazy(() => import('views/Waybill/Print'))

const routes = [
  {
    path: '/transaction/waybills',
    name: 'Surat Jalan',
    component: MainWaybill,
    exact: true,
    noPadding: true,
  },
  {
    path: '/transaction/waybills/drivers',
    name: 'Driver Surat Jalan',
    component: WaybillDriver,
    exact: true,
    noPadding: true,
  },
  {
    path: '/transaction/waybills/add',
    name: 'Tambah Surat Jalan',
    component: FormWaybill,
    exact: true,
    feature_id: 68,
  },
  {
    path: '/transaction/waybills/:id',
    name: 'Detail Surat Jalan',
    component: DetailWaybill,
    exact: true,
  },
  {
    path: '/transaction/waybills/:id/edit',
    name: 'Edit Surat Jalan',
    component: FormWaybill,
    exact: true,
    feature_id: 69,
  },
  {
    path: '/transaction/waybills/drivers/:id',
    name: 'Driver Daftar Surat Jalan',
    component: WaybillDriverWaybill,
    exact: true,
  },
  {
    path: '/print/waybill',
    name: 'Print Waybill Details',
    component: PrintWaybillDetailPage,
    exact: true,
  },
]

export default routes
