import * as Sentry from '@sentry/react'
import { SENTRY_DSN, NODE_ENV } from 'config/env'

export const setupSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: NODE_ENV === 'staging' ? 0 : 0.02,
    sampleRate: NODE_ENV === 'staging' ? 0 : 0.1,
  })
}
