import React from 'react'
import { Relative, Text } from '../../../assets/components/components'
import { colors } from '../../../assets/styles/styles'
import master from '../../../utils/master'
import Checkbox from '../../Input/Checkbox'
import Select from '../../Input/Select'

const Second = ({ form, errors, onChange, channels, setChannels }) => {
  return (
    <div>
      <Select
        label="Apa harapanmu setelah menggunakan Onstock?"
        name="hopes"
        id="hopes"
        value={form?.hopes}
        error={errors?.hopes}
        options={master.businessHopes}
        onChange={onChange}
      />

      <Text margin="0 0 10px">Dimana saja kamu menawarkan produkmu?</Text>
      {channels.map((g, i) => (
        <Relative key={i} margin="0 0 10px">
          <Checkbox
            color="primary"
            checked={g.checked}
            label={g.name}
            onChange={() => {
              let newdata = [...channels]
              newdata[i].checked = !newdata[i].checked
              setChannels(newdata)
            }}
          />
        </Relative>
      ))}
      {errors?.channels ? (
        <Text color={colors?.red}>{errors.channels}</Text>
      ) : null}
    </div>
  )
}

export default Second
