import { CModal, CModalBody } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'
import { Flex, Relative } from '../../assets/components/components'
import { deepCopy, trimPhoneNumber } from '../../utils/format'
import master from '../../utils/master'
import ajaxProfile from '../../views/Settings/Settings/ajax'
import Button from '../Button/Button'
import ErrorForm from '../Error/ErrorForm'
import ajax from './ajax'
import First from './components/First'
import Second from './components/Second'
import Third from './components/Third'

const inputs = {
  // first
  period: '',
  field: '',

  // second
  hopes: '',

  source: '',
  other: '',
  category: '',
  image: '',
  name: '',
  phone: '',
  address: '',
  businesstype: '',
}

const err = {
  // first
  period: '',
  field: '',

  // second
  hopes: '',

  source: '',
  other: '',
  category: '',
  image: '',
  name: '',
  phone: '',
  address: '',
  businesstype: '',
}

const steps = {
  1: First,
  2: Second,
  3: Third,
}

const Form = ({ step, ...props }) => {
  let Component = steps[step]
  return <Component {...props} />
}

const ModalOnboardingProfile = ({ open, close, refetch }) => {
  const [step, setStep] = useState(3)
  const [form, setForm] = useState(deepCopy(inputs))
  const [errors, setErrors] = useState({ ...err })
  const [error, setError] = useState('')
  const [done, setDone] = useState(false)

  const [types, setTypes] = useState(master?.businessType || [])
  const [channels, setChannels] = useState(master?.businessChannels || [])
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)
  const isNewUser = useSelector((state) => state.isNewUser)

  const onChange = (e) => {
    const value =
      e.target.type == 'checkbox' ? e.target.checked : e.target.value
    setForm({ ...form, [e.target.name]: value })
  }

  const [getstate, fetch] = useAsyncFn(async (user, token, isNewUser) => {
    const res = await ajaxProfile.getProfile(token)
    if (!res.success) {
      return false
    }
    dispatch({ type: 'login', user: { ...user, ...res.data.user }, token, isNewUser })
  }, [])

  const validate = (step, form, types, channels) => {
    let err = {}
    let count = 0
    if (step == 1) {
      if (!form?.period) {
        err.period = 'Mohon isi kolom ini'
        count++
      }
      if (!form?.field) {
        err.field = 'Mohon isi kolom ini'
        count++
      }
      if (!types.find((g) => g.checked)) {
        err.types = 'Mohon pilih jenis bisnismu'
        count++
      }
    } else if (step == 2) {
      if (!form?.hopes) {
        err.hopes = 'Mohon isi kolom ini'
        count++
      }
      if (!channels.find((g) => g.checked)) {
        err.channels = 'Mohon pilih channel penjualan'
        count++
      }
    } else {
      if (!form?.name) {
        err.name = 'Mohon isi kolom ini'
        count++
      }
      if (!form?.phone) {
        err.phone = 'Mohon isi kolom ini'
        count++
      }
      // if (!form?.address) {
      //   err.address = 'Mohon isi kolom ini'
      //   count++
      // }
      if (!form?.businesstype) {
        err.businesstype = 'Mohon isi kolom ini'
        count++
      }
    }
    setErrors(err)
    return count
  }

  const [state, submit] = useAsyncFn(
    async (step, form, types, channels, user, token, isNewUser) => {
      setError('')

      // let type_ids = []
      // types.map((g) => {
      //   if (g.checked) type_ids.push(g.value)
      //   return g
      // })
      // let channel_ids = []
      // channels.map((g) => {
      //   if (g.checked) channel_ids.push(g.value)
      //   return g
      // })
      // const res0 = await ajax.saveOnboarding(
      //   form?.period,
      //   form?.field,
      //   type_ids,
      //   form?.hopes,
      //   channel_ids,
      //   token,
      // )
      // if (!res0.success) {
      //   setError(res.message)
      //   return false
      // }
      const res = await ajax.updateProfile(
        form?.name,
        form?.phone ? '+62' + form?.phone : '',
        form?.address,
        form?.image,
        form?.businesstype || '',
        token,
      )
      if (!res.success) {
        setError(res.message)
        return false
      }
      setDone(true)
      fetch(user, token, isNewUser)
      dispatch({
        type: 'update',
        onboardingProfile: false,
        onboardingLocation: true,
      })
    },
    [],
  )

  const checkClosed = () => {
    if (!done) dispatch({ type: 'update', onboardingProfile: true })
  }

  useEffect(() => {
    setForm({
      ...form,
      phone: user?.store?.phone_number
        ? trimPhoneNumber(user?.store?.phone_number)
        : '',
    })
  }, [user])

  return (
    <CModal
      show={open}
      closeOnBackdrop={false}
      onClose={() => dispatch({ type: 'update', onboardingProfile: false })}
      onClosed={checkClosed}
    >
      <ErrorForm error={error} />
      <CModalBody>
        <Relative>
          <Form
            step={step}
            form={form}
            setForm={setForm}
            errors={errors}
            types={types}
            setTypes={setTypes}
            channels={channels}
            setChannels={setChannels}
            onChange={onChange}
          />

          <Flex justifyContent="flex-end" margin="20px 0 0">
            <Button
              color="primary"
              padding="10px 24px"
              radius="20px"
              loading={state.loading}
              onClick={() => {
                if (validate(step, form, types, channels) > 0) return false
                if (step < 3) {
                  setStep(step + 1)
                  return false
                }
                submit(step, form, types, channels, user, token, isNewUser)
              }}
            >
              Lanjutkan
            </Button>
          </Flex>
        </Relative>
      </CModalBody>
    </CModal>
  )
}

export default ModalOnboardingProfile
