import imageCompression from 'browser-image-compression'
import DOMPurify from 'dompurify'
import moment from 'moment'
import { saveAs } from 'file-saver'

moment.updateLocale('id', {
  months:
    'Januari_Februari_Maret_April_Mei_Juni_Juli_Agustus_September_Oktober_November_Desember'.split(
      '_',
    ),
  monthsShort: 'Jan_Feb_Mar_Apr_Mei_Jun_Jul_Agu_Sep_Okt_Nov_Des'.split('_'),
  monthsParseExact: true,
  weekdays: 'Minggu_Senin_Selasa_Rabu_Kamis_Jumat_Sabtu'.split('_'),
  weekdaysShort: 'Min_Sen_Sel_Rab_Kam_Jum_Sab'.split('_'),
  weekdaysMin: 'Mi_Se_Se_Ra_Ka_Ju_Sa'.split('_'),
  weekdaysParseExact: true,
})

export function deepCopy(arr) {
  return JSON.parse(JSON.stringify(arr))
}

export const cmToPixel = (val) => {
  return (val * 96) / 2.54
}

export function checkOverdue(due_date, checkHour = false) {
  let duedate = moment(
    checkHour ? due_date : due_date.split(' ')[0] + ' 00:00:00',
    'YYYY-MM-DD HH:mm:ss',
  )
  let today = moment(
    checkHour
      ? moment().format('YYYY-MM-DD HH:mm:ss')
      : moment().format('YYYY-MM-DD') + ' 00:00:00',
    'YYYY-MM-DD HH:mm:ss',
  )
  let overdue = today.isAfter(duedate)
  let diff = duedate.diff(today, 'days')
  return {
    overdue,
    diff,
  }
}

export function billWhatsapp(
  number = '',
  name = '',
  amount = 0,
  deadline,
  link,
) {
  let phone = number || ''
  if (phone[0] == '0') phone = phone.substring(1, phone.length)
  if (phone[0] == '6' && phone[1] == '2')
    phone = phone.substring(2, phone.length)
  phone = '+62' + phone
  window.open(`https://wa.me/${phone}?text=
	Hai, ${name}
	%0a
	Pembayaran kamu sebesar Rp${formatMoney(amount || 0)} belum diterima
	${
    deadline
      ? `%0aSilahkan selesaikan pembayaran sebelum ${moment(
          deadline,
          'YYYY-MM-DD HH:mm:ss',
        ).format('dddd, DD MMMM YYYY HH:mm')}.`
      : ''
  }
	%0a
	Link Pembayaran: ${link}
	%0a%0a
	(Pesan ini dibuat oleh Onstock)
	`)
}

const compressOptions = {
  maxWidthOrHeight: 256,
  // initialQuality: 0.7,
}

export async function compressImage(image) {
  let res = await imageCompression(image, compressOptions)
  return res
}

export function trimPhoneNumber(number) {
  if (number[0] == '0') {
    number = number.substring(1, number.length)
  }
  if (number[0] == '+' && number[1] == '6' && number[2] == '2') {
    number = number.substring(3, number.length)
  }
  return number
}

export async function generateQueryParams(
  obj,
  alias = {},
  blacklist = {
    total_data: true,
    total_page: true,
    current_page: true,
  },
) {
  let query = ''
  Object.keys(obj).map((g, i) => {
    if (!blacklist[g]) {
      if (i > 0) query += '&'
      query += `${alias[g] || g}=${obj[g]}`
    }
  })
  return query
}

export function generateVariantName(user, product, separator = ',') {
  let string = ''
  if (user?.custom_column_1_status) {
    string += `${user?.custom_column_1_value}: ${
      product?.custom_column_1 || '-'
    }${separator} `
  }
  if (user?.custom_column_2_status) {
    string += `${user?.custom_column_2_value}: ${
      product?.custom_column_2 || '-'
    }${separator} `
  }
  if (user?.custom_column_3_status) {
    string += `${user?.custom_column_3_value}: ${
      product?.custom_column_3 || '-'
    }`
  }
  return string
}

export function getRandomColor() {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export function renderPDFCell(data, header) {
  let content = data[header?.alias] || ''
  // if (header.date) {
  //   content = moment(content, 'YYYY-MM-DD HH:mm:ss').format(
  //     header.dateFormat || 'YYYY-MM-DD',
  //   )
  // }
  // if (header.currency) {
  //   content = formatMoney(content)
  // }
  // if (header.append) {
  //   content = content + header.append
  // }
  // if (header.prepend) {
  //   content = header.prepend + content
  // }
  // if (header.mapping) {
  //   content = header.list[content]
  // }
  // if (header.fallback) {
  //   content = content || header.fallback
  // }
  return content
}

export function numberOnly(value) {
  return value.toString().replace(/[^0-9]/g, '')
}

export function numberAndMinus(value) {
  return value.toString().replace(/[^0-9\-]/g, '')
}

export function numberAndDotOnly(value) {
  return value.toString().replace(/[^0-9\.]/g, '')
}

export function numberAndCommaOnly(value) {
  return value.toString().replace(/[^0-9\,]/g, '')
}

export function phoneOnly(value) {
  return value.toString().replace(/[^0-9\+]/g, '')
}

export function formatMoney(
  value = 0,
  separator = '.',
  returnNegative = false,
) {
  let returnValue = parseFloat(value)
  returnValue = returnValue
    .toString()
    .split('.')
    .join(',')
    .replace(/[^0-9,]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  return `${returnNegative && parseInt(value) < 0 ? '-' : ''}${returnValue}`
}

export function formatNumberMoney(value = 0, separator = '.') {
  return value
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

export function formatDecimalMoney(value = 0) {
  let arr = []
  if (value) {
    value = value.toString()
    arr = value.split(',')
    let x1 = arr[0]
    x1 = x1.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    let x2 = ''
    if (arr?.length > 1) {
      x2 = arr[1].replace(/\D/g, '')
      return x1 + ',' + x2
    }
    return x1
  } else {
    return value
  }
}

export function timeout(ms, promise) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error('timeout'))
    }, ms)
    promise.then(resolve, reject)
  })
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const truncateString = (string, length) =>
  !string
    ? '-'
    : string.length > length
    ? `${string.slice(0, length)}...`
    : string

const timeouttime = 18000000
const errormessage =
  'Terjadi kesalahan pada server. Mohon hubungi tim Onstock atau coba beberapa saat lagi.'

export async function fetchGet(
  url,
  token,
  withToken = true,
  returnBlob = false,
) {
  let responseJSON
  let headers = {
    Accept: 'application/json',
    'X-Platform-Source': 'Website',
  }

  if (withToken) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    }
  }

  await timeout(
    timeouttime,
    fetch(url, {
      headers,
    }),
  )
    .then(async function (response) {
      if (response.status === 200) {
        if (returnBlob) responseJSON = await response.blob()
        else {
          responseJSON = await response.json()
          responseJSON = {
            ...responseJSON,
            success:
              responseJSON.Status === 'OK' || responseJSON.status === 'OK',
          }

          if (
            !responseJSON?.success &&
            responseJSON?.message == 'Unauthenticated'
          ) {
            responseJSON = {
              success: false,
              message: 'forbidden',
              forbidden: true,
            }
          }
        }
      } else if (response.status === 401 || response.status === 403) {
        responseJSON = {
          success: false,
          message: 'forbidden',
          forbidden: true,
        }
      } else {
        responseJSON = {
          success: false,
          message: errormessage,
        }
      }
    })
    .catch(function (error) {
      responseJSON = {
        success: false,
        message:
          'Terjadi kesalahan pada koneksi. Mohon hubungi tim Onstock atau coba beberapa saat lagi.',
      }
    })
  return responseJSON
}

export async function fetchPost(
  url,
  data,
  token,
  useJSON = true,
  withToken = true,
) {
  let responseJSON
  let headers = {
    Accept: 'application/json',
    'X-Platform-Source': 'Website',
  }

  if (useJSON) {
    headers = {
      ...headers,
      'Content-Type': 'application/json',
    }
  }

  if (withToken) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    }
  }

  await timeout(
    timeouttime,
    fetch(url, {
      method: 'POST',
      headers,
      body: data,
    }),
  )
    .then(async function (response) {
      if (response.status === 200) {
        responseJSON = await response.json()
        if (!responseJSON.status) {
          responseJSON = {
            data: responseJSON,
            status: 'OK',
          }
        }
        responseJSON = {
          ...responseJSON,
          success: responseJSON.Status === 'OK' || responseJSON.status === 'OK',
        }
      } else if (response.status === 401 || response.status === 403) {
        responseJSON = {
          success: false,
          message: 'forbidden',
          forbidden: true,
        }
      } else {
        responseJSON = {
          success: false,
          message: errormessage,
        }
      }
    })
    .catch(function (error) {
      responseJSON = {
        success: false,
        message:
          'Terjadi kesalahan pada koneksi. Mohon hubungi tim Onstock atau coba beberapa saat lagi.',
      }
    })
  return responseJSON
}

// format: DD/MM/YYYY, exp: 05/01/2022
export const dateTimeFormatter = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

// format: DD/MM/YYYY HH:mm , exp: 05/01/2022 17:30
export const dateTimeFormatter1 = (date) => {
  return moment(date).format('DD/MM/YYYY HH:mm')
}

// format: dddd, DD MM YYYY HH:mm, exp: Sunday, 05 January 2022 05:20
export const dateTimeFormatter2 = (date) => {
  return moment(date).format('dddd, DD MMMM YYYY HH:mm')
}

// format: dddd, DD MM YYYY exp: Sunday, 05 January 2022
export const dateTimeFormatter3 = (date) => {
  return moment(date).format('dddd, DD MMMM YYYY')
}

// format: HH:mm exp: 17:50
export const dateTimeFormatter4 = (date) => {
  return moment(date).format('HH:mm')
}

// format: DD MM YYYY exp:  05 January 2022
export const dateTimeFormatter5 = (date) => {
  return moment(date).format('DD MMMM YYYY')
}

// format: DD MM YYYY exp:  05 January 2022, 16:50
export const dateTimeFormatter6 = (date) => {
  return moment(date).format('DD MMMM YYYY HH:mm')
}

// format: DDMMYYYY
export const dateTimeFormatter7 = (date) => {
  return moment(date).format('DDMMYYYY')
}

export const sanitizedHtml = (data) => {
  return { __html: DOMPurify.sanitize(data) }
}

export const downloadUrl = (
  url,
  fileName,
  dispatch,
  fileFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const newBlob = new Blob([blob], {
        type: `${fileFormat};charset=utf-8`,
      })
      saveAs(newBlob, fileName)
    })
    .catch((error) => {
      dispatch({
        type: 'addtoast',
        toast: {
          show: true,
          color: 'danger',
          title: 'Gagal',
          message: 'Gagal mengunduh data',
        },
      })
    })
}

export const checkRegex = (text, type) => {
  let regex = ''

  if (type === 'emoji') {
    regex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
  }

  if (type === 'special_character') {
    regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/
  }

  return regex.test(text)
}

export const removeEmoji = (text) => {
  const regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
  return text.replace(regex, '')
}
