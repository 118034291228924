import React from 'react'

import waybill from "./routes/waybill";
// authentication
const Login = React.lazy(() => import('./views/Authentication/Login/Login'))
const ForgotPassword = React.lazy(() =>
  import('./views/Authentication/ForgotPassword/ForgotPassword'),
)
const OTPPassword = React.lazy(() =>
  import('./views/Authentication/ForgotPassword/OTPPassword'),
)
const ResetPassword = React.lazy(() =>
  import('./views/Authentication/ForgotPassword/ResetPassword'),
)
const PhoneVerification = React.lazy(() =>
  import('./views/Authentication/PhoneVerification/PhoneVerification'),
)
const PhoneVerificationOTP = React.lazy(() =>
  import('./views/Authentication/PhoneVerification/PhoneVerificationOTP'),
)

// dashboard
const Dashboard = React.lazy(() =>
  import('./views/Dashboard/Dashboard/Dashboard'),
)

// inventory
const Product = React.lazy(() => import('./views/Inventory/Inventory'))
const ProductAdd = React.lazy(() =>
  import('./views/Inventory/ProductAdd/ProductAdd'),
)
const ProductDetails = React.lazy(() =>
  import('./views/Inventory/ProductDetails/ProductDetails'),
)
const ProductionAdd = React.lazy(() =>
  import('./views/Inventory/ProductionAdd/ProductionAdd'),
)
const ProductionEdit = React.lazy(() =>
  import('./views/Inventory/ProductionAdd/ProductionEdit'),
)
const ProductionDetails = React.lazy(() =>
  import('./views/Inventory/ProductionDetails/ProductionDetails'),
)
const BundleAdd = React.lazy(() =>
  import('./views/Inventory/BundleAdd/BundleAdd'),
)
const BundleEdit = React.lazy(() =>
  import('./views/Inventory/BundleAdd/BundleEdit'),
)
const BundleDetails = React.lazy(() =>
  import('./views/Inventory/BundleDetails/BundleDetails'),
)
const StockOpname = React.lazy(() =>
  import('./views/Transaction/StockOpname/StockOpname'),
)
const StockOpnameAdd = React.lazy(() =>
  import('./views/Transaction/StockOpnameAdd/StockOpnameAdd'),
)
const StockOpnameImportHistory = React.lazy(() =>
  import(
    './views/Transaction/StockOpnameImportHistory/StockOpnameImportHistory'
  ),
)
const ProductCategory = React.lazy(() =>
  import('./views/Inventory/ProductCategory/ProductCategory'),
)

// data center
const Employee = React.lazy(() =>
  import('./views/DataCenter/Employee/Employee'),
)
const EmployeeAdd = React.lazy(() =>
  import('./views/DataCenter/Employee/Employees/Add'),
)
const EmployeeDetails = React.lazy(() =>
  import('./views/DataCenter/Employee/Employees/Details'),
)
const EmployeeDetailsChangePassword = React.lazy(() =>
  import('./views/DataCenter/Employee/Employees/Details/ChangePassword'),
)
const AccessRightAdd = React.lazy(() =>
  import('./views/DataCenter/AccessRightAdd/AccessRightAdd'),
)
const Supplier = React.lazy(() =>
  import('./views/DataCenter/Supplier/Supplier'),
)
const Store = React.lazy(() => import('./views/DataCenter/Store/Store'))
const Customer = React.lazy(() =>
  import('./views/DataCenter/Customer/Customer'),
)
const CustomerTransactions = React.lazy(() =>
  import('./views/DataCenter/CustomerTransactions/CustomerTransactions'),
)

// warehouse
const Location = React.lazy(() => import('./views/Warehouse/Location/Location'))
const Transfer = React.lazy(() => import('./views/Warehouse/Transfer/Transfer'))
const TransferAdd = React.lazy(() =>
  import('./views/Warehouse/TransferAdd/TransferAdd'),
)

// transaction
const Inbound = React.lazy(() => import('./views/Transaction/Inbound/Inbound'))
const InboundAdd = React.lazy(() =>
  import('./views/Transaction/InboundAdd/InboundAdd'),
)
const InboundEdit = React.lazy(() =>
  import('./views/Transaction/InboundAdd/InboundEdit'),
)
const InboundImport = React.lazy(() =>
  import('./views/Transaction/InboundImport/InboundImport'),
)
const InboundImportHistory = React.lazy(() =>
  import('./views/Transaction/InboundImportHistory/InboundImportHistory'),
)
const InboundImportHistoryDetails = React.lazy(() =>
  import(
    './views/Transaction/InboundImportHistory/InboundImportHistoryDetails'
  ),
)

const Outbound = React.lazy(() =>
  import('./views/Transaction/Outbound/Outbound'),
)
const OutboundDetails = React.lazy(() =>
  import('./views/Transaction/OutboundDetails/OutboundDetails'),
)
const OutboundAdd = React.lazy(() =>
  import('./views/Transaction/OutboundAdd/OutboundAdd'),
)
const OutboundEdit = React.lazy(() =>
  import('./views/Transaction/OutboundAdd/OutboundEdit'),
)
const OutboundImport = React.lazy(() =>
  import('./views/Transaction/OutboundImport/OutboundImport'),
)
const OutboundInvoice = React.lazy(() =>
  import('./views/Transaction/OutboundInvoice/OutboundInvoice'),
)
const OutboundImportHistory = React.lazy(() =>
  import('./views/Transaction/OutboundImportHistory/OutboundImportHistory'),
)
const OutboundImportHistoryDetails = React.lazy(() =>
  import(
    './views/Transaction/OutboundImportHistory/OutboundImportHistoryDetails'
  ),
)
const OutboundImportMarketplaceNeedConfirmation = React.lazy(() =>
  import(
    './views/Transaction/OutboundImportHistory/OutboundImportMarketplaceNeedConfirmation'
  ),
)
const OutboundImportMarketplaceNeedReview = React.lazy(() =>
  import(
    './views/Transaction/OutboundImportHistory/OutboundImportMarketplaceNeedReview'
  ),
)
const OutboundImportMarketplaceInvalid = React.lazy(() =>
  import(
    './views/Transaction/OutboundImportHistory/OutboundImportMarketplaceInvalid'
  ),
)
const OutboundImportMarketplaceHistoryDetails = React.lazy(() =>
  import(
    './views/Transaction/OutboundImportHistory/OutboundImportMarketplaceHistoryDetails'
  ),
)
const Production = React.lazy(() =>
  import('./views/Transaction/Production/Production'),
)
const TransactionProductionAdd = React.lazy(() =>
  import('./views/Transaction/ProductionAdd/ProductionAdd'),
)

// accounting
const Outcome = React.lazy(() => import('./views/Spending/Outcome/Outcome'))
const Income = React.lazy(() => import('./views/Spending/Income/Income'))
const Debt = React.lazy(() => import('./views/Accounting/Debt/Debt'))
const DebtDetails = React.lazy(() =>
  import('./views/Accounting/Debt/DebtDetails'),
)
const DebtTransactionDetails = React.lazy(() =>
  import('./views/Accounting/DebtTransactionDetails'),
)
const DebtDueDate = React.lazy(() => import('./views/Accounting/DebtDueDate'))
const Credit = React.lazy(() => import('./views/Accounting/Credit/Credit'))
const CreditDetails = React.lazy(() =>
  import('./views/Accounting/Credit/CreditDetails'),
)
const CreditTransactionDetails = React.lazy(() =>
  import('./views/Accounting/CreditTransactionDetails'),
)
const CreditDueDate = React.lazy(() =>
  import('./views/Accounting/CreditDueDate'),
)
const Bank = React.lazy(() => import('./views/Finance/Bank/Bank'))
const Balance = React.lazy(() => import('./views/Finance/Balance/Balance'))

// report
const Sales = React.lazy(() => import('./views/Report/Sales/Sales'))
const ReportInbound = React.lazy(() => import('./views/Report/Inbound/Inbound'))
const ProfitAndLoss = React.lazy(() =>
  import('./views/Report/ProfitAndLoss/ProfitAndLoss'),
)
const Return = React.lazy(() => import('./views/Report/Return/Return'))
const ReportProduct = React.lazy(() => import('./views/Report/Product/Product'))
const ReportCreditDebt = React.lazy(() =>
  import('./views/Report/CreditDebt/CreditDebt'),
)
const CashFlow = React.lazy(() => import('./views/Report/CashFlow/CashFlow'))

// settings
const Settings = React.lazy(() => import('./views/Settings/Settings/Settings'))
const CustomProductExpirationAdd = React.lazy(() =>
  import('./views/Settings/CustomProductExpiration/Add'),
)
const CustomProductExpirationEdit = React.lazy(() =>
  import('./views/Settings/CustomProductExpiration/Edit'),
)
const CustomPpnAdd = React.lazy(() => import('./views/Settings/CustomPpn/Add'))
const CustomPpnEdit = React.lazy(() =>
  import('./views/Settings/CustomPpn/Edit'),
)

// billing
const Billing = React.lazy(() => import('./views/Billing/Billing/Billing'))
const BillingPackage = React.lazy(() =>
  import('./views/Billing/Billing/ChoosePackage/ChoosePackage'),
)
const BillingAddOns = React.lazy(() =>
  import('./views/Billing/Billing/ChooseAddOns/ChooseAddOns'),
)
const BillingPayment = React.lazy(() =>
  import('./views/Billing/Billing/ChoosePayment/ChoosePayment'),
)
const Referral = React.lazy(() => import('./views/Billing/Referral/Referral'))

// print
const PrintResi = React.lazy(() => import('./views/Print/PrintResi/PrintResi'))
const PrintReceipt = React.lazy(() =>
  import('./views/Print/PrintReceipt/PrintReceipt'),
)
const PrintBarcode = React.lazy(() =>
  import('./views/Print/PrintBarcode/PrintBarcode'),
)
const PrintOpname = React.lazy(() =>
  import('./views/Print/PrintOpname/PrintOpname'),
)

// pdf
const SalesPDF = React.lazy(() =>
  import('./views/Report/Sales/Sales/components/PDF'),
)
const RankingPDF = React.lazy(() =>
  import('./views/Report/Sales/Ranking/components/PDF'),
)
const SummaryPDF = React.lazy(() =>
  import('./views/Report/Sales/Summary/components/PDF'),
)
const InboundPDF = React.lazy(() =>
  import('./views/Report/Inbound/components/PDF'),
)
const ProfitAndLossPDF = React.lazy(() =>
  import('./views/Report/ProfitAndLoss/components/PDF'),
)
const ReturnPDF = React.lazy(() =>
  import('./views/Report/Return/components/PDF'),
)
const ProductPDF = React.lazy(() =>
  import('./views/Report/Product/Stock/components/PDF'),
)
const StockOpnamePDF = React.lazy(() =>
  import('./views/Report/Product/StockOpname/components/PDF'),
)
const CustomerPDF = React.lazy(() =>
  import('./views/Report/Sales/Customer/components/PDF'),
)
const CreditPDF = React.lazy(() =>
  import('./views/Report/CreditDebt/Credit/components/PDF'),
)
const DebtPDF = React.lazy(() =>
  import('./views/Report/CreditDebt/Debt/components/PDF'),
)
const CashFlowPDF = React.lazy(() =>
  import('./views/Report/CashFlow/components/PDF'),
)

// invoice (public view)
const InvoiceOutbound = React.lazy(() =>
  import('./views/Invoice/Outbound/Outbound'),
)

const routes = [
  // dashboard
  { path: '/', name: 'Dashboard', component: Dashboard, exact: true },

  // authentication
  {
    path: '/login',
    name: 'Login',
    component: Login,
    exact: true,
    public: true,
  },
  {
    path: '/forgot',
    name: 'Forgot Password',
    component: ForgotPassword,
    exact: true,
    public: true,
  },
  {
    path: '/forgot/otp',
    name: 'OTP',
    component: OTPPassword,
    exact: true,
    public: true,
  },
  {
    path: '/forgot/reset',
    name: 'Reset Password',
    component: ResetPassword,
    exact: true,
    public: true,
  },
  {
    path: '/verification/phone',
    name: 'Verifikasi Handphone',
    component: PhoneVerification,
    exact: true,
    public: true,
  },
  {
    path: '/verification/phone/otp/:id',
    name: 'Verifikasi Handphone',
    component: PhoneVerificationOTP,
    exact: true,
    public: true,
  },

  // data center
  {
    path: '/data/employee',
    name: 'Pegawai',
    component: Employee,
    exact: true,
    noPadding: true,
  },
  {
    path: '/data/employee/add',
    name: 'Tambah Pegawai',
    component: EmployeeAdd,
    exact: true,
  },
  {
    path: '/data/employee/edit/:id',
    name: 'Ubah Pegawai',
    component: EmployeeAdd,
    exact: true,
  },
  {
    path: '/data/employee/details/:id',
    name: 'Rincian Pegawai',
    component: EmployeeDetails,
    exact: true,
  },
  {
    path: '/data/employee/details/changepassword/:id',
    name: 'Ubah Password Pegawai',
    component: EmployeeDetailsChangePassword,
    exact: true,
  },
  {
    path: '/data/access',
    name: 'Hak Akses',
    component: Employee,
    exact: true,
    noPadding: true,
  },
  {
    path: '/data/access/add',
    name: 'Tambah Hak Akses',
    component: AccessRightAdd,
    exact: true,
  },
  {
    path: '/data/access/edit/:id',
    name: 'Ubah Hak Akses',
    component: AccessRightAdd,
    exact: true,
  },
  {
    path: '/data/supplier',
    name: 'Supplier',
    component: Supplier,
    exact: true,
  },
  {
    path: '/data/store',
    name: 'Toko',
    component: Store,
    exact: true,
  },
  {
    path: '/data/customer',
    name: 'Customer',
    component: Customer,
    exact: true,
  },
  {
    path: '/data/customer/transactions/:id',
    name: 'Customer Transactions',
    component: CustomerTransactions,
    exact: true,
  },

  // warehouse
  {
    path: '/warehouse/location',
    name: 'Lokasi Gudang',
    component: Location,
    exact: true,
  },
  {
    path: '/warehouse/transfer',
    name: 'Transfer Barang',
    component: Transfer,
    exact: true,
    noPadding: true,
  },
  {
    path: '/warehouse/transfer/add',
    name: 'Tambah Transfer Barang',
    component: TransferAdd,
    exact: true,
  },

  // inventory
  {
    path: '/product',
    name: 'Daftar Produk',
    component: Product,
    exact: true,
    noPadding: true,
    feature_id: 2,
  },
  {
    path: '/bundle',
    name: 'Daftar Produk Paket',
    component: Product,
    exact: true,
    noPadding: true,
    feature_id: 2,
  },
  {
    path: '/product/add',
    name: 'Tambah Produk',
    component: ProductAdd,
    exact: true,
  },
  {
    path: '/product/edit/:id',
    name: 'Edit Produk',
    component: ProductAdd,
    exact: true,
  },
  {
    path: '/product/details/:id',
    name: 'Rincian Barang',
    component: ProductDetails,
    exact: true,
    noPadding: true,
  },
  {
    path: '/production/add',
    name: 'Tambah Barang Produksi',
    component: ProductionAdd,
    exact: true,
  },
  {
    path: '/production/edit/:id',
    name: 'Edit Barang Produksi',
    component: ProductionEdit,
    exact: true,
  },
  {
    path: '/production/details/:id',
    name: 'Rincian Barang Produksi',
    component: ProductionDetails,
    exact: true,
    noPadding: true,
  },
  {
    path: '/bundle/add',
    name: 'Tambah Produk Paket',
    component: BundleAdd,
    exact: true,
  },
  {
    path: '/bundle/edit/:id',
    name: 'Edit Produk Paket',
    component: BundleEdit,
    exact: true,
  },
  {
    path: '/bundle/details/:id',
    name: 'Rincian Produk Paket',
    component: BundleDetails,
    exact: true,
    noPadding: true,
  },
  {
    path: '/product/opname',
    name: 'Stock Opname',
    component: StockOpname,
    exact: true,
    feature_id: 29,
  },
  {
    path: '/product/opname/add',
    name: 'Tambah Stock Opname',
    component: StockOpnameAdd,
    exact: true,
  },
  {
    path: '/product/opname/history/import',
    name: 'Histori Import',
    component: StockOpnameImportHistory,
    exact: true,
    noPadding: true,
  },
  {
    path: '/product-category',
    name: 'Daftar Produk Kategori',
    component: Product,
    exact: true,
    noPadding: true,
    feature_id: 2,
  },

  // transaction
  {
    path: '/transaction/outbound',
    name: 'Penjualan',
    component: Outbound,
    exact: true,
  },
  {
    path: '/transaction/outbound/details/:id',
    name: 'Rincian Penjualan',
    component: OutboundDetails,
    exact: true,
  },
  {
    path: '/transaction/outbound/add',
    name: 'Tambah Penjualan',
    component: OutboundAdd,
    exact: true,
    feature_id: 40,
  },
  {
    path: '/transaction/outbound/edit/:id',
    name: 'Edit Penjualan',
    component: OutboundEdit,
    exact: true,
    feature_id: 41,
  },
  {
    path: '/transaction/outbound/invoice/:id',
    name: 'Invoicing',
    component: OutboundInvoice,
    exact: true,
  },
  {
    path: '/transaction/outbound/import',
    name: 'Import Penjualan',
    component: OutboundImport,
    exact: true,
  },
  {
    path: '/transaction/outbound/history/import',
    name: 'Histori Import',
    component: OutboundImportHistory,
    exact: true,
  },
  {
    path: '/transaction/outbound/history/import/:id',
    name: 'Rincian Histori Import',
    component: OutboundImportHistoryDetails,
    exact: true,
  },
  {
    path: '/transaction/outbound/history/import-marketplace',
    name: 'Histori Import Marketplace',
    component: OutboundImport,
    exact: true,
  },
  {
    path: '/transaction/outbound/history/import-marketplace/need-confirmation/:id',
    name: 'Import Marketplace Need Confirmation',
    component: OutboundImportMarketplaceNeedConfirmation,
    exact: true,
  },
  {
    path: '/transaction/outbound/history/import-marketplace/need-review/:id',
    name: 'Import Marketplace Need Review',
    component: OutboundImportMarketplaceNeedReview,
    exact: true,
  },
  {
    path: '/transaction/outbound/history/import-marketplace/invalid-data/:id',
    name: 'Import Marketplace Invalid Data',
    component: OutboundImportMarketplaceInvalid,
    exact: true,
  },
  {
    path: '/transaction/outbound/history/import-marketplace/:id',
    name: 'Rincian Histori Gagal Import Marketplace',
    component: OutboundImportMarketplaceHistoryDetails,
    exact: true,
  },
  {
    path: '/transaction/inbound',
    name: 'Pembelian',
    component: Inbound,
    exact: true,
  },
  {
    path: '/transaction/inbound/add',
    name: 'Tambah Pembelian',
    component: InboundAdd,
    exact: true,
    feature_id: 43,
  },
  {
    path: '/transaction/inbound/duplicate/:id',
    name: 'Duplikasi Pembelian',
    component: InboundAdd,
    exact: true,
    feature_id: 43,
  },
  {
    path: '/transaction/inbound/edit/:id',
    name: 'Edit Pembelian',
    component: InboundEdit,
    exact: true,
    feature_id: 44,
  },
  {
    path: '/transaction/inbound/import/:id',
    name: 'Import Pembelian',
    component: InboundImport,
    exact: true,
  },
  {
    path: '/transaction/inbound/history/import',
    name: 'Histori Import',
    component: InboundImportHistory,
    exact: true,
    noPadding: true,
  },
  {
    path: '/transaction/inbound/history/import/:id',
    name: 'Rincian Histori Import',
    component: InboundImportHistoryDetails,
    exact: true,
  },
  {
    path: '/transaction/production',
    name: 'Transaksi Produksi',
    component: Production,
    exact: true,
  },
  {
    path: '/transaction/production/add',
    name: 'Tambah Transaksi Produksi',
    component: TransactionProductionAdd,
    exact: true,
  },
  ...waybill,

  // accounting
  {
    path: '/accounting/outcome',
    name: 'Pengeluaran',
    component: Outcome,
    exact: true,
    noPadding: true,
  },
  {
    path: '/accounting/income',
    name: 'Pemasukan',
    component: Income,
    exact: true,
    noPadding: true,
  },
  {
    path: '/accounting/debt',
    name: 'Utang',
    component: Debt,
    exact: true,
  },
  {
    path: '/accounting/debt/transaction/:id',
    name: 'Detail Transaksi Utang',
    component: DebtTransactionDetails,
    exact: true,
  },
  {
    path: '/accounting/debt/detail/:id',
    name: 'Rincian Utang',
    component: DebtDetails,
    exact: true,
  },
  {
    path: '/accounting/debt/due-date',
    name: 'Jatuh Tempo Utang',
    component: DebtDueDate,
    exact: true,
  },
  {
    path: '/accounting/credit',
    name: 'Piutang',
    component: Credit,
    exact: true,
  },
  {
    path: '/accounting/credit/transaction/:id',
    name: 'Detail Transaksi Piutang',
    component: CreditTransactionDetails,
    exact: true,
  },
  {
    path: '/accounting/credit/detail/:id',
    name: 'Piutang',
    component: CreditDetails,
    exact: true,
  },
  {
    path: '/accounting/credit/due-date',
    name: 'Jatuh Tempo Piutang',
    component: CreditDueDate,
    exact: true,
  },

  // finance
  {
    path: '/finance/balance',
    name: 'Saldo & Status Bayar',
    component: Balance,
    exact: true,
    noPadding: true,
  },
  {
    path: '/finance/bank',
    name: 'Rekening',
    component: Bank,
    exact: true,
  },

  // report
  {
    path: '/report/sales',
    name: 'Laporan Penjualan',
    component: Sales,
    exact: true,
    noPadding: true,
  },
  {
    path: '/report/inbound',
    name: 'Laporan Pembelian',
    component: ReportInbound,
    exact: true,
  },
  {
    path: '/report/profitandloss',
    name: 'Laporan Laba Rugi',
    component: ProfitAndLoss,
    exact: true,
  },
  {
    path: '/report/return',
    name: 'Laporan Retur',
    component: Return,
    exact: true,
  },
  {
    path: '/report/product',
    name: 'Laporan Produk',
    component: ReportProduct,
    exact: true,
    noPadding: true,
  },
  {
    path: '/report/creditdebt',
    name: 'Laporan Utang Piutang',
    component: ReportCreditDebt,
    exact: true,
    noPadding: true,
  },
  {
    path: '/report/cashflow',
    name: 'Laporan Arus Kas',
    component: CashFlow,
    exact: true,
  },

  // settings
  {
    path: '/settings',
    name: 'Pengaturan',
    component: Settings,
    exact: true,
  },
  {
    path: '/settings/product-expiration/add',
    name: 'Tambah Pengaturan Kadaluarsa',
    component: CustomProductExpirationAdd,
    exact: true,
  },
  {
    path: '/settings/product-expiration/edit/:id',
    name: 'Edit Pengaturan Kadaluarsa',
    component: CustomProductExpirationEdit,
    exact: true,
  },
  {
    path: '/settings/ppn/add',
    name: 'Tambah Pengaturan PPn',
    component: CustomPpnAdd,
    exact: true,
  },
  {
    path: '/settings/ppn/edit/:id',
    name: 'Edit Pengaturan PPn',
    component: CustomPpnEdit,
    exact: true,
  },

  // billing
  {
    path: '/billing',
    name: 'Paket Langganan',
    component: Billing,
    exact: true,
  },
  // {
  //   path: '/billing/package',
  //   name: 'Pilih Paket',
  //   component: BillingPackage,
  //   exact: true,
  // },
  // {
  //   path: '/billing/addons',
  //   name: 'Pilih Paket',
  //   component: BillingAddOns,
  //   exact: true,
  // },
  // {
  //   path: '/billing/payment',
  //   name: 'Pilih Pembayaran',
  //   component: BillingPayment,
  //   exact: true,
  // },
  {
    path: '/referral',
    name: 'Referral Saya',
    component: Referral,
    exact: true,
  },

  // print
  {
    path: '/print/resi',
    name: 'Print Resi',
    component: PrintResi,
    exact: true,
    noLayout: true,
  },
  {
    path: '/print/receipt/:id',
    name: 'Print Receipt',
    component: PrintReceipt,
    exact: true,
    noLayout: true,
  },
  {
    path: '/print/barcode',
    name: 'Print Barcode',
    component: PrintBarcode,
    exact: true,
    noLayout: true,
  },
  {
    path: '/print/opname/:id',
    name: 'Print Stok Opname',
    component: PrintOpname,
    exact: true,
    noLayout: true,
  },

  // pdf
  {
    path: '/report/sales/pdf',
    name: 'Laporan Penjualan',
    component: SalesPDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/ranking/pdf',
    name: 'Ranking Penjualan',
    component: RankingPDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/summary/pdf',
    name: 'Summary Penjualan',
    component: SummaryPDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/inbound/pdf',
    name: 'Laporan Pembelian',
    component: InboundPDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/profitandloss/pdf',
    name: 'Laporan Laba Rugi',
    component: ProfitAndLossPDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/return/pdf',
    name: 'Laporan Retur',
    component: ReturnPDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/stock/pdf',
    name: 'Laporan Produk',
    component: ProductPDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/opname/pdf',
    name: 'Laporan Stok Opname',
    component: StockOpnamePDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/customer/pdf',
    name: 'Laporan Customer',
    component: CustomerPDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/credit/pdf',
    name: 'Laporan Utang',
    component: CreditPDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/debt/pdf',
    name: 'Laporan Piutang',
    component: DebtPDF,
    exact: true,
    noLayout: true,
  },
  {
    path: '/report/cashflow/pdf',
    name: 'Laporan Arus Kas',
    component: CashFlowPDF,
    exact: true,
    noLayout: true,
  },

  // invoice
  {
    path: '/invoice/:id',
    name: 'Invoice',
    component: InvoiceOutbound,
    exact: true,
    noLayout: true,
    public: true,
  },
]

export default routes
