import React from 'react'
import {
  CFormGroup,
  CLabel,
  CTextarea,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
} from '@coreui/react'

import { Text } from 'assets/components/components'

const Textarea = React.forwardRef(
  (
    {
      label,
      id,
      append,
      prepend,
      wrapperStyle,
      labelStyle,
      error,
      invalid,
      height = 100,
      style,
      required,
      hint,
      ...props
    },
    ref,
  ) => {
    return (
      <CFormGroup style={wrapperStyle}>
        {label && (
          <CLabel htmlFor={id} style={labelStyle}>
            {label} {required && <span className="form-star-required">*</span>}
          </CLabel>
        )}
        <CInputGroup>
          {prepend && (
            <CInputGroupPrepend>
              <CInputGroupText>{prepend}</CInputGroupText>
            </CInputGroupPrepend>
          )}
          <CTextarea
            {...props}
            id={id}
            invalid={invalid ? true : false}
            ref={ref}
            className="o-textarea"
            required={required}
            style={{ height, ...style }}
          />
          {append && (
            <CInputGroupPrepend>
              <CInputGroupText>{append}</CInputGroupText>
            </CInputGroupPrepend>
          )}

          <CInvalidFeedback>{error}</CInvalidFeedback>
        </CInputGroup>
        {hint && (
          <Text
            color="#9E9E9E"
            fontSize="14px"
            fontWeight="400"
            margin="4px 0 0"
          >
            {hint}
          </Text>
        )}
      </CFormGroup>
    )
  },
)

export default Textarea
