import { SECRET_GOOGLE_API_KEY } from 'config/env'

const googleapikey = SECRET_GOOGLE_API_KEY
const firebaseConfig = {
  // Project Settings => Add Firebase to your web app
  apiKey: 'AIzaSyDazjhC_m2bR9p-A5SkSrYFqv8dA4TTbgw',
  authDomain: 'onstock-3f7f4.firebaseapp.com',
  databaseURL: 'https://onstock-3f7f4.firebaseio.com',
  projectId: 'onstock-3f7f4',
  storageBucket: 'onstock-3f7f4.appspot.com',
  messagingSenderId: '440631950569',
  appId: '1:440631950569:web:21d4829ff09bee8e0d0478',
  measurementId: 'G-2V7ERYJTD9',
}

export { googleapikey, firebaseConfig }
