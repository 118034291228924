import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react'
import Button from '../Button/Button'
import { Flex } from '../../assets/components/components'
import Maps from '../Maps/Maps'
import {
  googleMapURL,
  loadingElement,
  containerElement,
  mapElement,
  defaultLocation,
} from '../Maps/MapsUtilities'
import Geocode from 'react-geocode'

const Location = ({ open, close, value = defaultLocation, onChange }) => {
  const [fetching, setFetching] = useState(false)
  const [loading, setLoading] = useState(false)
  const [latlng, setLatlng] = useState(value)
  const isMounted = useRef(true)

  useEffect(() => {
    if (value) {
      setLatlng(value)
    } else setLatlng(defaultLocation)
  }, [open])

  const fetch = useCallback(
    async (latlng) => {
      if (fetching) return
      setFetching(true)

      const res = await Geocode.fromLatLng(latlng.latitude, latlng.longitude)

      const address = res.results[0]?.formatted_address || ''

      if (isMounted.current) {
        setFetching(false)
        setLoading(false)
      }

      return {
        latitude: latlng.latitude,
        longitude: latlng.longitude,
        address,
      }
    },
    [fetching],
  )

  const submit = async (latlng) => {
    let res = await fetch(latlng)
    onChange(res)
    close()
  }

  return (
    <CModal show={open} onClose={close}>
      <CModalHeader>
        <CModalTitle>Pilih Lokasi</CModalTitle>
      </CModalHeader>
      <CModalBody style={{ padding: 0 }}>
        <div>
          <Maps
            googleMapURL={googleMapURL}
            loadingElement={loadingElement}
            containerElement={containerElement}
            mapElement={mapElement}
            markers={[latlng]}
            onClick={(e) => {
              let lat = e.latLng.lat()
              let lng = e.latLng.lng()
              setLatlng({ lat, lng, latitude: lat, longitude: lng })
            }}
          />
        </div>
        <Flex justifyContent="flex-end" padding="15px" margin="10px 0 0">
          <Button color="primary-link" onClick={close}>
            Batal
          </Button>
          <Button
            color="primary"
            padding="10px 24px"
            radius="20px"
            margin="0 0 0 10px"
            loading={loading}
            onClick={() => {
              setLoading(true)
              submit(latlng)
            }}
          >
            Pilih Lokasi
          </Button>
        </Flex>
      </CModalBody>
    </CModal>
  )
}

export default Location
