import React from 'react'
import { googleapikey } from '../../utils/key'

const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${googleapikey}`

const loadingElement = <div style={{ height: `100%` }} />
const containerElement = <div style={{ height: `360px` }} />
const mapElement = <div style={{ height: `100%` }} />
const defaultLocation = {
  lat: -6.1753924,
  lng: 106.8249641,
  latitude: -6.1753924,
  longitude: 106.8249641,
}

export {
  googleMapURL,
  loadingElement,
  containerElement,
  mapElement,
  defaultLocation,
}
