import { baseURL } from '../../utils/apihost'
import { fetchPost } from '../../utils/format'

export default {
  async saveOnboarding(
    business_length,
    business_category,
    business_type,
    onstock_wish,
    initial_channel,
    token,
  ) {
    let form = JSON.stringify({
      business_length,
      business_category,
      business_type,
      onstock_wish,
      initial_channel,
    })
    let responseJSON = await fetchPost(
      baseURL + '/user/doInitialProfiling',
      form,
      token,
    )
    return responseJSON
  },

  async updateProfile(
    name,
    phone_number,
    address,
    img_logo,
    business_category,
    token,
  ) {
    let FormData = require('form-data')
    let form = new FormData()
    form.append('name', name)
    form.append('phone_number', phone_number)
    form.append('address', address)
    form.append('img_logo', img_logo)
    form.append('business_category', business_category)
    let responseJSON = await fetchPost(
      baseURL + '/store/setProfile',
      form,
      token,
      false,
    )
    return responseJSON
  },
}
