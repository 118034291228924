import React from 'react'
import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import { icons } from './assets/icons'
import './polyfill'
import store from './redux/store'
import * as serviceWorker from './serviceWorker'
import * as firebase from 'firebase'
import { setupSentry } from 'services/sentry'
import * as dotenv from 'dotenv'

React.icons = icons

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('./firebase-messaging-sw.js')
//     .then(function (registration) {
//       console.log('Registration successful, scope is:', registration.scope)
//     })
//     .catch(function (err) {
//       console.log('Service worker registration failed, error:', err)
//     })
// }

setupSentry()
dotenv.config()

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
